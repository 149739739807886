import { useSection } from '../../../stores/useSection';
import { SoundButton } from '../buttons/SoundButton';
import style from './Header.module.css';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { MenuButton } from './MenuButton';
import { Logo } from './Logo';

export function Header() {

  console.log('Header render');
  /*
   * properties
   */

  // console.log('Header render');

  const [shown, setShown] = useState(false);
  const segment = useSection((state) => state.segment);

  // const activeSection = useSection((state) => state.activeSection);

  const wrapper = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    // if (!shown && segment === 'tree') {
    if (!shown && segment === 'intro') {
      setShown(true);
    }
  }, [segment]);

  useEffect(() => {
    if (shown) {
      // gsap.from(wrapper.current, { opacity: 0, delay: 1, y: -20, duration: 2.5, ease: 'power4.out' });
    }
  }, [shown]);

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <header ref={wrapper} className={style.header}>
          {/* <img src="/images/logo.svg" className={style.logo} /> */}

          <div className={style.logo}>
            <Logo />
          </div>

          <div className={style.buttons}>
            <SoundButton />
            <MenuButton />
          </div>
        </header>
      )}
    </>
  );
}
