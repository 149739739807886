import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Object } from './Object';

export function Objects({content}) {
  const objs = [
    "Coins", "Bee", "Ram", "Cross", "Skull", "Ghost"
  ]

  return (
    <>
      {
        objs.map((obj, index) => {

          const data = content?.points?.length && content?.points.find((item) => item.model === obj);

          return <Object key={index} index={index} content={data} section={obj} />
         })
      }
    </>
  );
}
