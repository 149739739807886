import { useEffect, useMemo, useRef, useState } from 'react'
import gsap from 'gsap'
import { Html } from '@react-three/drei'
import style from './Grid.module.css'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { StandardButton } from '../../../../../app2D/component/buttons/StandardButton'

export function Grid({ content, hide, allPanelContent }) {
  /*
   * properties
   */

  const allPreviousSlices = allPanelContent
    ?.filter((item) => item._type !== 'gridSlice')
    .map((slice) => slice.text)

  console.log('GRID CONTENT::', { allPreviousSlices, allPanelContent })

  const container = useRef()

  /*
   * hooks
   */

  useEffect(() => {
    show()
  }, [])

  const show = () => {
    if (container.current) {
      gsap.set(container.current.children, { x: 200, opacity: 0 })
      gsap.to(container.current.children, {
        duration: 0.8,
        delay: 1,
        stagger: 0.05,
        opacity: 1,
        x: 0,
        ease: 'power4.out',
      })
    }
  }

  useEffect(() => {
    if (hide) {
      gsap.to(container.current.children, {
        duration: 0.2,
        delay: 0,
        stagger: 0.03,
        opacity: 0,
        x: 0,
        ease: 'power1.out',
      })
    }
  }, [hide])

  /*
   * visuals
   */

  console.log({ allPreviousSlices })

  return (
    <>
      <div ref={container} className={style.container}>
        <h1 className={style.title}>{content.title}</h1>
        {allPreviousSlices &&
          allPreviousSlices.map((text, index) => {
            if (!text.includes(': ')) return

            const [label, statistic] = text.split(': ')

            return (
              <div key={index} className={style.row}>
                <div>{statistic}</div>
                <div>{label}</div>
              </div>
            )
          })}
      </div>
    </>
  )
}
