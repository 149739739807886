import { folder, useControls } from 'leva';
import { Perf } from 'r3f-perf';
import { Box, Environment } from '@react-three/drei';
import { useSection } from '../../stores/useSection';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { useLight } from '../../stores/useLight';

export function Staging() {
  /*
   * properties
   */

  const isOn = useLight((state) => state.isOn);
  const lightPosition = useLight((state) => state.lightPosition);

  // const temp = useRef();
  const pointLight = useRef();

  // const config = useControls('Staging', {
  //   'performance panel': folder({
  //     showFPS: { value: false, label: 'show' },
  //   }),

  //   'stage color': folder({
  //     stageColor: { value: '#030303', label: 'color' },
  //   }),

  //   fog: folder({
  //     fogColor: { value: '#030303', label: 'color' },
  //     near: { value: 2, min: 0, max: 100, step: 0.01 },
  //     far: { value: 15, min: 0, max: 100, step: 0.01 },
  //   }),

  //   'ambient light': folder({
  //     ambientColor: { value: '#ffffff', label: 'color' },
  //     ambientIntensity: { value: 0.6, min: 0, max: 30, step: 0.01, label: 'intensity' },
  //   }),

  //   'directionalLight light 1': folder({
  //     dir1Color: { value: '#ffffff', label: 'color' },
  //     dir1Intensity: { value: 2.58, min: 0, max: 30, step: 0.01, label: 'intensity' },
  //     dir1Position: { value: { x: -0.5, y: -1.58, z: -0.66 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //   }),

  //   'directionalLight light 2': folder({
  //     dir2Color: { value: '#ffffff', label: 'color' },
  //     dir2Intensity: { value: 16.56, min: 0, max: 30, step: 0.01, label: 'intensity' },
  //     dir2Position: { value: { x: 0.1, y: 1.94, z: -0.99 }, min: -5, max: 5, step: 0.01, label: 'position' },
  //   }),
  // });

  const config = {
    showFPS: false,

    stageColor: '#030303',

    fogColor: '#030303',
    near: 2,
    far: 11,

    ambientColor: '#ffffff',
    ambientIntensity: 0.6,

    dir1Color: '#ffffff',
    dir1Intensity: 2.58,
    dir1Position: { x: -0.5, y: -1.58, z: -0.66 },

    dir2Color: '#ffffff',
    dir2Intensity: 16.56,
    dir2Position: { x: 0.1, y: 1.94, z: -0.99 },
  };

  const segment = useSection((state) => state.segment);

  const directionalLight1 = useRef();
  const directionalLight2 = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    if (segment === 'tree') {
      gsap.to(directionalLight1.current, {
        intensity: config.dir1Intensity,
        delay: 0.8,
        duration: 1.4,
        ease: 'sine.out',
      });
      gsap.to(directionalLight2.current, {
        intensity: config.dir2Intensity,
        delay: 1.1,
        duration: 1.4,
        ease: 'sine.out',
      });
    }
  }, [segment]);

  useEffect(() => {
    const segment = useSection.getState().segment;

    if (!(segment === 'loader' || segment === 'intro')) {
      // console.log('really switch');

      const intensity1 = isOn ? config.dir1Intensity : config.dir1Intensity * 0.1;
      const intensity2 = isOn ? config.dir2Intensity : config.dir2Intensity * 0.1;

      gsap.to(directionalLight1.current, {
        intensity: intensity1,
        duration: 0.5,
        ease: 'sine.out',
      });
      gsap.to(directionalLight2.current, {
        intensity: intensity2,
        duration: 0.5,
        ease: 'sine.out',
      });
    }
  }, [isOn]);

  useEffect(() => {
    gsap.killTweensOf(pointLight.current);

    if (lightPosition) {
      const pos = lightPosition;

      if (pos.x > 0) {
        pos.x += 0.6;
      } else {
        pos.x -= 0.6;
      }

      pos.z += 0.75;

      pointLight.current.position.copy(pos);
      pointLight.current.visible = true;
      gsap.to(pointLight.current, {
        intensity: 3,
        duration: 0.5,
        ease: 'sine.out',
      });
    } else {
      gsap.to(pointLight.current, {
        intensity: 0,
        duration: 0.5,
        ease: 'sine.out',
        onComplete: () => {
          pointLight.current.visible = false;
        },
      });
    }
  }, [lightPosition]);

  /*
   * visuals
   */

  return (
    <>
      <fog attach="fog" color={config.fogColor} near={config.near} far={config.far} />

      <pointLight
        visible={false}
        ref={pointLight}
        distance={1.8}
        decay={2}
        position={[3, 2.75, 1.8]}
        intensity={0}
        color="#ffffff"
      />

      {/* <Box ref={temp} scale={0.2} position={[3, 2.75, 1.8]} /> */}

      <directionalLight
        ref={directionalLight1}
        color={config.dir1Color}
        intensity={config.dir1Intensity * 0.1}
        position={[config.dir1Position.x, config.dir1Position.y, config.dir1Position.z]}
      />

      <directionalLight
        ref={directionalLight2}
        color={config.dir2Color}
        intensity={config.dir2Intensity * 0.1}
        position={[config.dir2Position.x, config.dir2Position.y, config.dir2Position.z]}
      />

      <color attach="background" args={[config.stageColor]} />

      {config.showFPS && <Perf position="top-left" />}
    </>
  );
}
