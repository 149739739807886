import { useSection } from '../../../stores/useSection';
import { useCamera } from '../../../stores/useCamera';
import { Icon } from '../Icon';
import { StandardButton } from '../buttons/StandardButton';
import style from './Center.module.css';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useContent } from '../../../stores/useContent';
import { useSectionScroll } from '../../../stores/useSectionScroll';

import gsap from 'gsap';
import { Responsive } from '../Responsive';
import { SmallRoundButton } from '../buttons/SmallRoundButton';
import { Line } from './Line';
import { useScene } from '../../../stores/useScene';
// import { useGesture } from '@use-gesture/react';

export function Center() {
  /*
   * properties
   */

  const wrapper = useRef();
  const background = useRef();

  const resetScrollTo = useSectionScroll((state) => state.resetScrollTo);
  const resetActiveSection = useSection((state) => state.resetActiveSection);
  const activeSection = useSection((state) => state.activeSection);
  const activeLevel = useSection((state) => state.activeLevel);
  const isShowingRoot = useScene((state) => state.isShowingRoot);
  // const setActiveLevel = useSection((state) => state.setActiveLevel);
  const scrollTo = useSectionScroll((state) => state.scrollTo);

  const resetCamera = useCamera((state) => state.resetCamera);

  const active = useRef(false);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  /*
   * hooks
   */

  useEffect(() => {
    document.addEventListener('keydown', keyPress);

    return () => {
      document.removeEventListener('keydown', keyPress);
    };
  }, []);

  const keyPress = (e) => {
    if (e.key === 'Escape') {
      backHandler();
    }
  };

  useEffect(() => {
    if (activeSection) {
      setTitle(useContent.getState().getTitle(activeSection));
      setContent(useContent.getState().getContent(activeSection));
      setShow(true);
      active.current = true;
    } else if (show) {
      gsap.to(background.current, {
        opacity: 0,
        duration: 0.2,
        ease: 'power1.in',
      });
      gsap.to(wrapper.current.children, {
        opacity: 0,
        duration: 0.2,
        stagger: 0.04,
        ease: 'power1.in',
        onComplete: hideHandler,
      });

      active.current = false;
    }
  }, [activeSection]);

  useEffect(() => {
    if (show) {
      gsap.to(background.current, {
        delay: 1,
        opacity: 1,
        duration: 1.5,
        ease: 'power1.out',
      });
      gsap.to(wrapper.current.children, {
        delay: 1,
        opacity: 1,
        duration: 1.5,
        stagger: 0.1,
        ease: 'power1.out',
      });
    }
  }, [show]);

  const hideHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    if (isShowingRoot) {
      backHandler();
    }
  }, [isShowingRoot]);

  const backHandler = () => {
    // console.log("backHandler")
    if (active.current) {
      resetActiveSection();
      resetScrollTo();
      resetCamera();
    }
  };

  const romanize = (num) => {
    var lookup = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 },
      roman = '',
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={background} className={style.background} />
      <div ref={wrapper} className={style.center}>
        {show && (
          <>
            <div className={style.caption}>
              <div className={style.iconWrapper}>
                <Icon svg="loop" />
              </div>
              <p className={style.title}>{title}</p>
            </div>
            {content &&
              content.length > 1 &&
              content.length <= 6 &&
              content.map((value, index) => (
                <Fragment key={index}>
                  <Line index={index} />
                  <SmallRoundButton onClick={() => scrollTo(index)} highlight={index <= activeLevel}>
                    {romanize(index + 1)}
                  </SmallRoundButton>
                </Fragment>
              ))}

            {content && content.length > 1 && content.length > 6 && (
              <Fragment>
                <Line index={0} single={true} />
                <SmallRoundButton
                  onClick={() => scrollTo(content.length - 1)}
                  highlight={content.length - 1 <= activeLevel}
                  single={true}
                ></SmallRoundButton>
              </Fragment>
            )}

            <div className={style.mobile}>
              <StandardButton onClick={backHandler}>Back</StandardButton>
            </div>
            <div className={style.desktop}>
              <StandardButton onClick={backHandler}>
                <Icon svg="cross" /> Go Back
              </StandardButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
