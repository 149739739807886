import React, { useRef } from 'react';
import { useGLTF, useKTX2, useTexture } from '@react-three/drei';
// import { useControls } from 'leva';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';

// useGLTF.preload('/models/TreeScene/01_Omen_TreeScene_02.glb');
// useTexture.preload('/models/TreeScene/01_Omen_TreeScene_LANDSCAPE_diffuse_Baked_001.jpg');
// useTexture.preload('/models/TreeScene/01_Omen_TreeScene_LANDSCAPE_normal_001.jpg');
// useTexture.preload('/models/TreeScene/01_Omen_TreeScene_TREE_normal_001.jpg');

export function Landscape(props) {
  const { nodes, materials } = useGLTF('/models/TreeScene/01_Omen_TreeScene_02.glb');

  const [landscapeColorTexture, landscapeNormalTexture, treeNormalTexture] = useTexture([
    '/models/TreeScene/01_Omen_TreeScene_LANDSCAPE_diffuse_Baked_001.jpg',
    '/models/TreeScene/01_Omen_TreeScene_LANDSCAPE_normal_001.jpg',
    '/models/TreeScene/01_Omen_TreeScene_TREE_normal_001.jpg',
  ]);

  landscapeNormalTexture.wrapS = THREE.MirroredRepeatWrapping;
  landscapeNormalTexture.wrapT = THREE.MirroredRepeatWrapping;
  landscapeNormalTexture.repeat.set(2, 2);
  landscapeNormalTexture.offset.set(0.5, 0.5);

  const bigLandscapeNormalTexture = landscapeNormalTexture.clone();
  bigLandscapeNormalTexture.repeat.set(12, 12);

  // const config = useControls('generic material', {
  //   color: { value: '#dedede' },
  //   metalness: { value: 0.92, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 0.69, min: 0, max: 1, step: 0.01 },
  // });

  const config = {
    color: '#dedede',
    metalness: 0.92,
    roughness: 0.69,
  };

  // const treeConfig = useControls('tree material', {
  //   normalScale: { value: 0.4, min: 0, max: 2, step: 0.01 },
  // });

  const treeConfig = {
    normalScale: 0.4,
  };

  // const groundConfig = useControls('ground material', {
  //   color: { value: '#585858' },
  //   metalness: { value: 1.0, min: 0, max: 1, step: 0.01 },
  //   roughness: { value: 0.48, min: 0, max: 1, step: 0.01 },
  //   normalScale: { value: 0.1, min: 0, max: 2, step: 0.01 },
  // });

  const groundConfig = {
    color: '#585858',
    metalness: 1.0,
    roughness: 0.48,
    normalScale: 0.1,
  };

  // const bigGroundConfig = useControls('big ground material', {
  //   color: { value: '#0d0d0d' },
  // });

  const bigGroundConfig = {
    color: '#0d0d0d',
  };

  const genericMaterial = new THREE.MeshStandardMaterial({
    color: config.color,
    metalness: config.metalness,
    roughness: config.roughness,
  });

  const treeMaterial = new THREE.MeshStandardMaterial({
    color: config.color,
    metalness: config.metalness,
    roughness: config.roughness,
    normalMap: treeNormalTexture,
    normalScale: new THREE.Vector2(treeConfig.normalScale, treeConfig.normalScale),
  });

  const groundMaterial = new THREE.MeshStandardMaterial({
    color: bigGroundConfig.color,
    metalness: groundConfig.metalness,
    roughness: groundConfig.roughness,
    // envMapIntensity: groundConfig.envMapIntensity,
    normalMap: bigLandscapeNormalTexture,
    normalScale: new THREE.Vector2(groundConfig.normalScale, groundConfig.normalScale),
  });

  const landscapeMaterial = new THREE.MeshStandardMaterial({
    map: landscapeColorTexture,
    color: groundConfig.color,
    metalness: groundConfig.metalness,
    roughness: groundConfig.roughness,
    // envMapIntensity: groundConfig.envMapIntensity,
    normalMap: landscapeNormalTexture,
    normalScale: new THREE.Vector2(groundConfig.normalScale, groundConfig.normalScale),
  });

  const cardanoMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(36, 36, 36),
    transparent: true,
  });

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    cardanoMaterial.opacity = 0.5 + Math.abs(Math.sin(time * 3)) * 0.5;
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cardano.geometry}
        material={cardanoMaterial}
        position={[0.001, 0.014, -0.012]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tree_Black.geometry}
        material={nodes.Tree_Black.material}
        position={[0.001, 0.014, -0.012]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tree.geometry}
        material={treeMaterial}
        position={[0, 0.027, 0.005]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Skull_Rocks.geometry}
        material={genericMaterial}
        position={[-0.003, -0.006, 0.041]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Landscape.geometry}
        material={landscapeMaterial}
        position={[0, -0.014, -0.002]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={groundMaterial}
        position={[0, -0.014, -0.002]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </group>
  );
}
