import { Route, Routes, useLocation } from 'react-router-dom';

import { Home } from './pages/home/Home';
import { UI } from './pages/ui/UI';
import { useEffect } from 'react';
import { Header } from './component/header/Header';
import { Footer } from './component/footer/Footer';
// import style from './App2D.module.css';


export function App2D() {

  /*
   * properties
   */

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    let scale = window.innerWidth / 1440;
    let size = Math.max(0.75, 0.5 + scale * 0.5);

    var r = document.querySelector(':root');
    r.style.setProperty('--size', size);
    r.style.setProperty('--scale', scale);
  };

  /*
   * visuals
   */

  return (
    <>
      <Header />
      <Footer />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ui" element={<UI />} />
      </Routes>
    </>
  );
}
