import { shaderMaterial, useTexture } from '@react-three/drei';
import { useFrame, extend } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';
import { useSize } from '../../../stores/useSize';

const amount = 30;
// const width = 2;
// const height = 5;
// const depth = 2;

const Fairymaterial = shaderMaterial(
  {
    uTime: 0,
    uDpr: 1,
    uColor: new THREE.Color(1.0, 1.0, 1.0),
    uTexture: null,
    uResolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
  },
  vertexShader,
  fragmentShader
);

extend({ Fairymaterial });

export function Fairy({ color }) {
  /*
   * properties
   */
  const fairymaterial = useRef();
  const mesh = useRef();
  const dpr = useSize((state) => state.dpr);
  const wrapper = useRef();

  const texture = useTexture('/textures/blurredPoint-min.png');

  const points = useMemo(() => {
    const p = new Array(amount * 3);
    for (let i = 0; i < amount; i++) {
      const angle = Math.random() * 6.3;
      const radius = .8 + Math.random() * .5;
      p[i * 3 + 0] = Math.cos(angle) * radius;
      p[i * 3 + 1] = 0;
      p[i * 3 + 2] = Math.sin(angle) * radius;
    }

    return new Float32Array(p);
  }, [amount]);

  const rnd = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 10 * Math.random();
    }

    return new Float32Array(s);
  }, [amount]);

  // const sizes = useMemo(() => {
  //   const s = new Array(amount);
  //   for (let i = 0; i < amount; i++) {
  //     s[i] = 0.5 + Math.random() * 1.0;
  //   }

  //   return new Float32Array(s);
  // }, [amount]);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    // console.log('*** ' + dpr + ' ***')
    fairymaterial.current.uniforms.uDpr.value = dpr;
  }, [dpr]);

  const resizeHandler = () => {
    fairymaterial.current.uniforms.uResolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
  };

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    fairymaterial.current.uniforms.uTime.value = time * 0.6;

    wrapper.current.rotation.y = time * 0.4;
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} position={[0, 0, 0]}>
        {/* <mesh rotation={[-1.57, 0, 0]}>
          <planeGeometry args={[width, depth]} />
          <meshBasicMaterial color="#ffffff" />
        </mesh> */}

        <points ref={mesh}>
          <bufferGeometry>
            <bufferAttribute attach={'attributes-position'} args={[points, 3, false]} />
            <bufferAttribute attach={'attributes-aRnd'} args={[rnd, 1, false]} />
            {/* <bufferAttribute attach={'attributes-aSize'} args={[sizes, 1, false]} /> */}
          </bufferGeometry>
          <fairymaterial ref={fairymaterial} uColor={color} uTexture={texture} transparent={true} depthWrite={false} />
        </points>
      </group>
    </>
  );
}
