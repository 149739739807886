import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { Html } from '@react-three/drei';
import style from './Panel.module.css';
import { useSection } from '../../../../stores/useSection';
import { useCamera } from '../../../../stores/useCamera';
import { StandardButton } from '../../../../app2D/component/buttons/StandardButton';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { PanelDot } from './PanelDot';

export function PanelLine({ level, sign, redMaterial }) {
  /*
   * properties
   */

  const line = useRef();
  const wrapper = useRef();
  const isZooming = useCamera((state) => state.isZooming);

  /*
   * hooks
   */

  useFrame((state) => {
    if (isZooming) {
      let pct = useSection.getState().activeLevel - (level - 1);
      pct = Math.max(0, Math.min(1, pct));

      const otherScale = pct === 0 ? 0 : 1;

      line.current.scale.set(otherScale, pct, otherScale);
      line.current.position.y = 1.45 + (1 - pct) * 1.4;
    }
  });

  useEffect(() => {
    if (isZooming) {
      show();
    } else {
      hide();
    }
  }, [isZooming]);

  const show = () => {
    if (wrapper.current) {
      gsap.to(wrapper.current.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.5,
        ease: 'power4.out',
      });
    }
  };

  const hide = () => {
    if (wrapper.current) {
      gsap.to(wrapper.current.scale, {
        x: 0,
        // y: 0,
        z: 0,
        duration: 0.4,
        ease: 'power2.in',
      });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <group
        ref={wrapper}
        position={[1 * sign, -0.7, 0]}
        rotation={[1.57, 0, 0.54 * (Math.floor((((level - 1) / 2) * 3) % 2) === 0 ? sign : -sign)]}
      >
        <mesh ref={line} position={[0, 1.4, 0]} scale={0} material={redMaterial}>
          <cylinderGeometry args={[0.003, 0.003, 2.7]} />
        </mesh>
      </group>
    </>
  );
}
