import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { BigText } from '../text/BigText';

export function RootCopy() {
  /*
   * properties
   */

  //   const segment = useSection((state) => state.segment);
  const [showText, setShowText] = useState(true);

  const wrapper = useRef();

  /*
   * hooks
   */

  //   useEffect(() => {
  //     if (!showText && segment === 'intro') {
  //       setShowText(true);
  //     } else if (showText && segment !== 'intro') {
  //       setShowText(false);
  //     }
  //   }, [segment]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    const ratio = window.innerWidth / window.innerHeight;
    // console.log(ratio);

    const scale = Math.min(1, ratio);
    wrapper.current.scale.set(scale, scale, scale);
  };

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} position={[0, -0.5, 4]}>
        <BigText text="There is" position={[0.5, 0.7, 0]} show={showText} delay={0.5} />
        <BigText text="far More" position={[-1, 0, 0]} show={showText} delay={0.75} />
        <BigText text="to Come..." position={[0.5, -0.7, 0]} show={showText} delay={1.0} />
      </group>
    </>
  );
}
