import { RoundButton } from '../buttons/RoundButton';
import style from './MenuButton.module.css';
import { Icon } from '../Icon';
import { useEffect, useRef, useState } from 'react';
import { useScene } from '../../../stores/useScene';
import { useSection } from '../../../stores/useSection';
import { useCamera } from '../../../stores/useCamera';
import { useSounds } from '../../../stores/useSounds';

export function MenuButton() {
  /*
   * properties
   */

  const isShowingRoot = useScene((state) => state.isShowingRoot);
  const showRoot = useScene((state) => state.showRoot);
  const hideRoot = useScene((state) => state.hideRoot);
  const segment = useSection((state) => state.segment);

  const isSceneTransitioning = useScene((state) => state.isTransitioning);
  const isCameraTransitioning = useCamera((state) => state.isTransitioning);

  // const enabled = useRef(false);
  const [enabled, setEnabled] = useState(false)

  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  useEffect(() => {
    // enabled.current = !isSceneTransitioning && !isCameraTransitioning && segment === 'tree';
    setEnabled(!isSceneTransitioning && !isCameraTransitioning && segment === 'tree');

    if (isSceneTransitioning || isCameraTransitioning) {
      document.documentElement.classList.add('wait');
    } else {
      document.documentElement.classList.remove('wait');
    }
  }, [isSceneTransitioning, isCameraTransitioning, segment]);

  const toggleRoot = () => {
    // if (enabled.current) {
    const isShowingRoot = useScene.getState().isShowingRoot;

    playSound('transition');

    if (isShowingRoot) {
      hideRoot();
    } else {
      showRoot();
    }

    // enabled.current = false;
    // setTimeout(enable, 1700);
    // }
  };

  /*
   * visuals
   */

  return (
    <>
      <RoundButton onClick={toggleRoot} disabled={!enabled}>
        <div className={`${style.items} ${isShowingRoot ? style.isShowingRoot : ''} ${enabled ? '' : style.disabled}`}>
          <div className={style.square} />
          <div className={style.lineTop} />
          <div className={style.lineBottom} />
        </div>
      </RoundButton>
    </>
  );
}
