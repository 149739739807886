import { useEffect, useMemo, useRef, useState } from 'react';
import { Html } from '@react-three/drei';
import style from './Panel.module.css';
import { useSection } from '../../../../stores/useSection';
import { useCamera } from '../../../../stores/useCamera';
import { StandardButton } from '../../../../app2D/component/buttons/StandardButton';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { PanelDot } from './PanelDot';
import { PanelLine } from './PanelLine';
import { Default } from './content/Default';
import { Grid } from './content/Grid';
import { Link } from './content/Link';
import { OneLiner } from './content/OneLiner';
import { Coin } from './extra/Coin';
import { Cross } from './extra/Cross';
import { Extra } from './extra/Extra';
import { Sphere } from './extra/Sphere';

export function Panel({ index, section, content, level, total, allPanelContent }) {
  /*
   * properties
   */


  let sign = index < 3 ? -1 : 1;

  const activeSection = useSection((state) => state.activeSection);

  const wrapper = useRef();
  const html = useRef();
  const panel = useRef();

  const [shown, setShown] = useState(false);
  const [hide, setHide] = useState(false);

  const redMaterial = new THREE.MeshStandardMaterial({
    color: new THREE.Color(1 * 10, 0.02 * 10, 0.02 * 10),
  });


  /*
   * hooks
   */

  useEffect(() => {
    let timeoutId;
    if (activeSection === section) {
      setHide(false);
      setShown(true);
      setOpacity();
      timeoutId = setTimeout(() => {
        setOpacity();
      }, 100);
    } else if (shown) {
      setHide(true);

      timeoutId = setTimeout(() => {
        setShown(false);
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeSection]);

  useFrame((state) => {
    if (panel.current && useCamera.getState().isZooming) {
      setOpacity();
    }
  });

  const setOpacity = () => {
    const activeLevel = useSection.getState().activeLevel;
    let opacity = 1 - Math.abs(level - activeLevel) * 0.3;
    opacity = Math.pow(opacity, 3);
    if (panel && panel.current) {
      panel.current.style.opacity = opacity;
    }
  };

  /*
   * visuals
   */

  const type = content._type


  return (
    <>
      <group ref={wrapper} position={[Math.sin(level * 1.57) * 1.5 * sign, 0.1, level * -2.5]}>
        {shown && (
          <>
            <Html
              ref={html}
              wrapperClass={style.wrapper}
              zIndexRange={[100, 0]}
              // transform={content.type !== 'link'}
              transform={false}
              center
              distanceFactor={2.1}
              position={[sign * 1.1, 0, 0]}
            >
              <div ref={panel} className={style.panel}>
                {/* <Default content={content} hide={hide} /> */}
                {/* <Tokenomics content={content} hide={hide} /> */}

                {type === 'default' && <Default content={content} hide={hide} />}
                {type === 'gridSlice' && <Grid allPanelContent={allPanelContent?.slices} content={content} hide={hide} />}
                {type === 'linkSlice' && <Link content={content} hide={hide} />}
                {type === 'textSlice' && <OneLiner content={content} hide={hide} />}
              </div>
            </Html>
            {total > 1 && <PanelDot level={level} sign={sign} redMaterial={redMaterial} />}
            {level > 0 && <PanelLine level={level} sign={sign} redMaterial={redMaterial} />}

            <Extra sign={sign} hide={hide}>
              {section === 'Coins' && <Coin />}
              {section === 'Cross' && <Cross />}
              {section === 'Ghost' && <Sphere />}
            </Extra>
          </>
        )}
      </group>
    </>
  );
}
