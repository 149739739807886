import { create } from "zustand"
import { getDocumentByType, getPoints } from "../utils/sanity"

export const useSanityContent = create((set, get) => ({
  content: null,
  loadingContent: false,
  fetchSanityContent: async () => {
    set({ loadingContent: true })
    try {
      const points = await getPoints()
      const footer = await getDocumentByType('settings.footer')
      const seo = await getDocumentByType('settings.seo')

      set({ content: { points, seo, footer } })
    } catch (err) {
      console.log(err)
      set({ hasError: true })
    } finally {
      set({ loadingContent: false })
    }
  },
}))
