import './style.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { StrictMode, Suspense } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { App3D } from './app3D/App3D';
import { App2D } from './app2D/App2D';
import {Helmet} from "react-helmet";
import { MouseController } from './controllers/MouseController';
import { MainScroll } from './controllers/MainScroll';
import { Leva } from 'leva';
import { SectionScroll } from './controllers/SectionScroll';
import { MusicController } from './controllers/MusicController';
import { useSanityContent } from './stores/useSanityContent';
import { MetaHead } from './app2D/component/MetaHead';

/*
 * THEATRE studio end
 */

const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.querySelector('#root'));


root.render(
  <StrictMode>
    <BrowserRouter history={history}>
      {/* <div style={{ position: 'fixed', zIndex: -1 }}> */}
        {/* <Leva collapsed={false} /> */}
      {/* </div> */}

      <MouseController />
      <MusicController />
      <MetaHead/>

      <App2D />
      <Suspense>
        <MainScroll>
          <SectionScroll>
            <App3D />
          </SectionScroll>
        </MainScroll>
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);

function BrowserRouter({ children, history }) {
  let [state, dispatch] = React.useReducer((_, action) => action, {
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(dispatch), [history]);

  return <Router children={children} action={state.action} location={state.location} navigator={history} />;
}
