import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { Html } from '@react-three/drei';
import style from './OneLiner.module.css';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { StandardButton } from '../../../../../app2D/component/buttons/StandardButton';

export function OneLiner({ content, hide }) {
  /*
   * properties
   */

  const container = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    show();
  }, []);

  const show = () => {
    if (container.current) {
      gsap.set(container.current.children, { x: 200, opacity: 0 });
      gsap.to(container.current.children, {
        duration: 0.8,
        delay: 1,
        stagger: 0.05,
        opacity: 1,
        x: 0,
        ease: 'power4.out',
      });
    }
  };

  useEffect(() => {
    if (hide) {
      gsap.to(container.current.children, {
        duration: 0.2,
        delay: 0,
        stagger: 0.03,
        opacity: 0,
        x: 0,
        ease: 'power1.out',
      });
    }
  }, [hide]);

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.container}>
        <h2>{content.text}</h2>
      </div>
    </>
  );
}
