import { create } from 'zustand';

export const useScene = create((set) => ({
  isShowingRoot: false,
  showSection: '',
  isTransitioning: false,

  showRoot: () => set({ isShowingRoot: true }),
  hideRoot: (showSection) => set({ showSection, isShowingRoot: false }),

  resetShowSection: () => set({ showSection: '' }),

  startTransitioning: () => set({ isTransitioning: true }),
  stopTransitioning: () => set({ isTransitioning: false }),
}));
