import { useEffect } from 'react';
import { Alignment, Fit, Layout, useRive, useStateMachineInput } from 'rive-react';
import style from './Animation.module.css';
import { useProgress } from '@react-three/drei';
import { useLoader } from '../../../stores/useLoader';

export function Animation({ progress, enter }) {
  const setReady = useLoader((state) => state.setReady);
  //
  const STATE_MACHINE_NAME = 'omen_preloader';

  const { rive, RiveComponent } = useRive({
    src: '/riv/omen_preloader.riv',
    artboard: STATE_MACHINE_NAME,
    stateMachines: STATE_MACHINE_NAME,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.CenterCenter,
    }),
    autoplay: true,
  });

  const animProgress = useStateMachineInput(rive, STATE_MACHINE_NAME, 'percent-loaded');
  const animEnter = useStateMachineInput(rive, STATE_MACHINE_NAME, 'enter');

  useEffect(() => {
    if (rive && animProgress && animEnter) {
      setReady(true);
    }
  }, [rive, animProgress, animEnter]);

  useEffect(() => {
    // console.log(progress);
    // if (progress === 100)
    if (rive && animProgress) {
      animProgress.value = progress;
    }
    // }
  }, [progress]);

  useEffect(() => {
    if (enter) {
      animEnter.fire();
    }
  }, [enter]);

  return (
    <>
      <div className={`${style.container} ${enter ? style.hide : ''}`}>
        <RiveComponent />
      </div>
    </>
  );
}
