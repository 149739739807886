import style from './Footer.module.css';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Center } from './Center';
import { RootContent } from './RootContent';
import { EasterEgg } from './EasterEgg';

export function Footer() {
  /*
   * properties
   */

  //

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <footer className={style.footer}>
        <EasterEgg />
        <Center />
        <RootContent />
      </footer>
    </>
  );
}
