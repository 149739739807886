import { Helmet } from 'react-helmet'
import { useSanityContent } from '../../stores/useSanityContent'
import { urlFor } from '../../utils/sanity'

export const MetaHead = () => {
  const content = useSanityContent((state) => state.content)

  const social = {
    title: content?.seo?.siteTitle || content?.seo?.social?.title,
    description: content?.seo?.description || content?.seo?.social?.description,
  }

  return (
    <Helmet>
      {content?.seo?.siteTitle && <title>{content.seo.siteTitle}</title>}
      {content?.seo?.description && (
        <meta name="description" content={content?.seo.description} />
      )}

      {/* OpenGraph for Facebook */}
      {content?.seo?.siteUrl && (
        <meta property="og:url" content={content?.seo.siteUrl} />
      )}

      {social?.title && <meta property={`og:title`} content={social.title} />}
      {social?.description && (
        <meta property={`og:description`} content={social.description} />
      )}
      {content?.seo?.siteTitle && (
        <meta property={`og:site_name`} content={content.seo.siteTitle} />
      )}

      <meta property={`og:type`} content="website" />
      <meta property={`og:locale`} content="en_US" />
      {content?.seo?.social?.image && (
        <meta property={`og:image`} content={content.seo.social.image} />
      )}
      {content?.seo?.social?.image && (
        <meta
          property={`og:image:alt`}
          content={content.seo.social.imageAlt || social.title}
        />
      )}

      {/* Twitter */}

      {content?.seo?.siteUrl && (
        <meta property="twitter:url" content={content?.seo.siteUrl} />
      )}

      {social?.title && (
        <meta property={`twitter:title`} content={social.title} />
      )}
      {social?.description && (
        <meta property={`twitter:description`} content={social.description} />
      )}
      {content?.seo?.siteTitle && (
        <meta property={`twitter:site_name`} content={content.seo.siteTitle} />
      )}

      <meta property={`twitter:type`} content="website" />
      <meta property={`twitter:locale`} content="en_US" />
      {content?.seo?.social?.image && (
        <meta property={`twitter:image`} content={content.seo.social.image} />
      )}
      {content?.seo?.social?.image && (
        <meta
          property={`twitter:image:alt`}
          content={content.seo.social.imageAlt || social.title}
        />
      )}

      {/* favicon */}

      {content?.seo?.favicon?.asset && (
        <link rel="icon" href={content?.seo?.favicon?.asset?.url} />
      )}
    </Helmet>
  )
}
