import { create } from 'zustand';

const deviceDpr = Math.min(2, window.devicePixelRatio);

export const useSize = create((set) => ({
  deviceDpr: deviceDpr,
  dpr: Math.min(deviceDpr, window.devicePixelRatio),
  demoMode: false,

  setDpr: (dpr) => set({ dpr }),
  setDemoMode: (demoMode) => set({ demoMode }),
}));
