import { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../../stores/useMouse';
import { useCamera } from '../../../stores/useCamera';
import gsap from 'gsap';

export function MouseWrapper({ children }) {
  /*
   * properties
   */

  const wrapper = useRef();
  const isZooming = useCamera((state) => state.isZooming);

  const movePct = useRef(1);

  /*
   * hooks
   */

  useEffect(() => {
    // console.log(isZooming);
    if (isZooming) {
      gsap.to(movePct, { current: 0, duration: 2, ease: 'sine.out' });
    } else {
      gsap.to(movePct, { current: 1 });
    }
  }, [isZooming]);

  //move camera on mouse move
  useFrame((state) => {
    const time = state.clock.elapsedTime;

    // console.log(movePct.current);

    // mouse and idle movement
    const { slowPctX, slowPctY, isTouch } = useMouse.getState();
    if (isTouch) {
      wrapper.current.rotation.y = Math.sin(time * 0.631) * 0.05 * movePct.current;
      wrapper.current.rotation.x = Math.sin(time * 0.542) * 0.025 * movePct.current;
    } else {
      wrapper.current.rotation.y = (-slowPctX * 0.08 + Math.sin(time * 0.631) * 0.05) * movePct.current;
      wrapper.current.rotation.x = (-slowPctY * 0.08 + Math.sin(time * 0.542) * 0.025) * movePct.current;
    }
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper}>{children}</group>
    </>
  );
}
