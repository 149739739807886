import { Route, Routes } from 'react-router-dom';

import {
  Clone,
  OrbitControls,
  PerformanceMonitor,
  PerspectiveCamera,
  Preload,
  SoftShadows,
  useGLTF,
  useKTX2,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { useSize } from '../stores/useSize';
import { useEffect, useState } from 'react';
import { CameraManager } from './core/CameraManager';
import { Staging } from './core/Staging';
import { PostProcessing } from './core/Postprocessing';

import { Home } from './pages/home/Home';
import { useLoader } from '../stores/useLoader';
import { useSanityContent } from '../stores/useSanityContent';

export function App3D() {
  /*
   * properties
   */

  const [fetchSanityContent, content] = useSanityContent((state) => [state.fetchSanityContent, state.content]);

  useEffect(() => {
    fetchSanityContent();
  }, [])

  const [post, setPost] = useState(true);
  const ready = useLoader((state) => state.ready);

  const dpr = useSize((state) => state.dpr);
  const setDpr = useSize((state) => state.setDpr);

  const setDemoMode = useSize((state) => state.setDemoMode);
  const demoMode = useSize((state) => state.demoMode);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('keypress', keyHandler);

    return () => {
      window.removeEventListener('keypress', keyHandler);
    };
  }, []);

  const keyHandler = (e) => {
    if (e.ctrlKey && e.shiftKey && e.code === 'KeyX') {
      setDemoMode(!useSize.getState().demoMode);
    }
  };

  useEffect(() => {
    if (demoMode) {
      // console.log('demo mode')
      const deviceDpr = Math.min(2, window.devicePixelRatio);
      setDpr(deviceDpr);
      setPost(true);
    }
  }, [demoMode]);

  const performanceChange = (state) => {
    if (!demoMode) {
      const sum = state.averages.reduce((acc, val) => acc + val, 0);
      const averageFps = state.averages.length ? sum / state.averages.length : 0;

      const deviceDpr = useSize.getState().deviceDpr;

      if (averageFps < 30) {
        // if (averageFps < 200) {
        if (deviceDpr * 0.75 >= 1 && dpr === deviceDpr && averageFps > 20) {
          // console.log('to 75%');
          setDpr(deviceDpr * 0.75);
        } else if (deviceDpr * 0.5 >= 1 && dpr > deviceDpr * 0.5) {
          // console.log('to 50%');
          setDpr(deviceDpr * 0.5);
        } else if (post) {
          // console.log('no post');
          setPost(false);
        }
      }
    }
  };

  return (
    <>
      {ready && (
        <Canvas
          dpr={dpr}
          gl={{
            powerPreference: 'high-performance',
            antialias: false,
            stencil: false,
          }}
        >
          <PerformanceMonitor
            ms={250}
            iterations={5}
            onChange={performanceChange}
            bounds={(refreshrate) => [40, 200]}
          />

          <Preload all />

          <CameraManager />
          <Staging />

          {post && <PostProcessing dpr={dpr} />}

          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Canvas>
      )}
    </>
  );
}
