/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { AnimatedObject } from './AnimatedObject';
import { useMaterials } from '../../../../stores/useMaterials';
import * as THREE from 'three';

// useGLTF.preload("/models/Floating_Objects/01_Omen_SKULL_01.glb");

export function Skull(props) {
  const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_SKULL_01.glb');
  // const genericMaterial = useMaterials((state) => state.genericMaterial);

  return (
    <>
      <AnimatedObject {...props} animations={animations}>
        <group name="Scene" scale={0.95} position={[0, 0, 0]}>
          <group name="Skull" rotation={[Math.PI / 2, 0, 0]}>
            <mesh
              name="Skull_2"
              castShadow
              receiveShadow
              geometry={nodes.Skull_2.geometry}
              material={props.material}
            />
            <mesh
              name="Skull_Jaw"
              castShadow
              receiveShadow
              geometry={nodes.Skull_Jaw.geometry}
              material={props.material}
              rotation={[0.035, 0, 0]}
            />
          </group>
        </group>
      </AnimatedObject>

      {/* <AnimatedObject {...props} animations={animations}>
        <group name="Scene" scale={0.95} position={[0, -0.4, 0]}>
          <group name="Skull" position={[0.2, .1, .05]} rotation={[0, 0, 0]}>
            <mesh name="Skull_2" castShadow receiveShadow geometry={nodes.Skull_2.geometry} material={redMaterial} />
            <mesh
              name="Skull_Jaw"
              castShadow
              receiveShadow
              geometry={nodes.Skull_Jaw.geometry}
              material={redMaterial}
              rotation={[0.035, 0, 0]}
            />
          </group>
        </group>
      </AnimatedObject> */}
    </>
  );
}
