/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

// useGLTF.preload("/models/Floating_Objects/01_Omen_SPHERE_01.glb");

export function Sphere(props) {
  const group = useRef();
  const { nodes } = useGLTF('/models/Floating_Objects/01_Omen_SPHERE_01.glb');

  const rnd = useMemo(() => {
    return Math.random() * 4;
  }, []);

  useFrame((state, delta) => {
    const time = state.clock.elapsedTime;
    group.current.scale.x = 1 + Math.sin(rnd + time * 0.9) * 0.15;
    group.current.scale.y = 1 + Math.cos(rnd + time * 0.43) * 0.1;
    group.current.scale.z = 1 + Math.sin(rnd + time * 0.24) * 0.05;
  });

  return (
    <group ref={group}>
      <group name="Scene" scale={0.2}>
        <group name="Sphere" rotation={[Math.PI / 2, 0, 0]}>
          <mesh name="Sphere_2" castShadow receiveShadow geometry={nodes.Sphere_2.geometry}>
            <meshStandardMaterial color="#333333" metalness={0.5} fog={true} roughness={0.8} />
          </mesh>
        </group>
      </group>
    </group>
  );
}
