import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useMaterials } from '../../../stores/useMaterials';
import * as THREE from 'three';

// useGLTF.preload('/models/Roots/03_Omen_ROOTS_01.glb');

export function RootModel(props) {
  const { nodes, materials } = useGLTF('/models/Roots/03_Omen_ROOTS_01.glb');

  const material = useMaterials((state) => state.genericMaterial);

  const goldMaterial = new THREE.MeshStandardMaterial({ color: '#f2a900', metalness: 0, roughness: 1 });

  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.003, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          geometry={nodes.Rock_1.geometry}
          material={nodes.Rock_1.material}
          // material={goldMaterial}
          position={[7.682, 19.022, 9.734]}
          rotation={[0.792, 0.092, -3.061]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_10.geometry}
          material={nodes.Rock_10.material}
          // material={goldMaterial}
          position={[7.735, 4.842, 7.695]}
          rotation={[0.003, -0.831, 2.022]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_11.geometry}
          material={nodes.Rock_11.material}
          // material={goldMaterial}
          position={[7.935, 4.842, 8.792]}
          rotation={[-0.576, 0.65, 1.949]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_12.geometry}
          material={nodes.Rock_12.material}
          // material={goldMaterial}
          position={[9.151, 4.842, 3.31]}
          rotation={[-0.576, 0.65, 1.949]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_13.geometry}
          material={nodes.Rock_13.material}
          // material={goldMaterial}
          position={[8.242, 8.702, 10.679]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_14.geometry}
          material={nodes.Rock_14.material}
          // material={goldMaterial}
          position={[-10.248, 11.229, 19.332]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_15.geometry}
          material={nodes.Rock_15.material}
          // material={goldMaterial}
          position={[9.475, 11.229, 19.332]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_16.geometry}
          material={nodes.Rock_16.material}
          // material={goldMaterial}
          position={[13.202, 11.229, 18.257]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_17.geometry}
          // material={nodes.Rock_17.material}
          material={goldMaterial}
          position={[10.208, 5.011, 2.073]}
          rotation={[-2.348, 0.311, 0.197]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_18.geometry}
          // material={nodes.Rock_18.material}
          material={goldMaterial}
          position={[-13.018, 4.577, 15.873]}
          rotation={[-1.311, -0.822, 1.009]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_19.geometry}
          // material={nodes.Rock_19.material}
          material={goldMaterial}
          position={[-4.996, 10.947, 16.474]}
          rotation={[-1.311, -0.822, 0.503]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_2.geometry}
          material={nodes.Rock_2.material}
          position={[-9.397, 11.455, 8.598]}
          rotation={[0.859, -0.633, -3.042]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_20.geometry}
          material={nodes.Rock_20.material}
          position={[-14.609, -6.335, 1.215]}
          rotation={[2.996, -0.308, -0.895]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_21.geometry}
          material={nodes.Rock_21.material}
          position={[-8.905, 4.307, 4.749]}
          rotation={[2.996, -0.308, 0.425]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_22.geometry}
          material={nodes.Rock_22.material}
          position={[-0.291, 4.307, 15.57]}
          rotation={[2.996, -0.308, 0.425]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_23.geometry}
          material={nodes.Rock_23.material}
          position={[14.635, 4.307, 6.583]}
          rotation={[2.996, -0.308, 0.425]}
          scale={[100, 193.603, 100]}
        />
        <mesh
          geometry={nodes.Rock_3.geometry}
          material={nodes.Rock_3.material}
          position={[-8.439, 4.842, 19.1]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_4.geometry}
          material={nodes.Rock_4.material}
          position={[-5.48, 4.842, 11.628]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_5.geometry}
          material={nodes.Rock_5.material}
          position={[4.815, 4.842, 17.887]}
          rotation={[1.263, 0.119, -3.111]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_6.geometry}
          material={nodes.Rock_6.material}
          position={[8.503, 4.842, 19.662]}
          rotation={[1.278, -0.369, -3.109]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_7.geometry}
          material={nodes.Rock_7.material}
          position={[11.951, 4.842, 5.162]}
          rotation={[-0.335, -0.018, 1.869]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_8.geometry}
          material={nodes.Rock_8.material}
          position={[11.951, 4.842, 10.308]}
          rotation={[-0.335, -0.018, 1.869]}
          scale={[56.346, 56.346, 104.352]}
        />
        <mesh
          geometry={nodes.Rock_9.geometry}
          material={nodes.Rock_9.material}
          position={[11.209, 4.842, 6.005]}
          rotation={[0.003, -0.831, 2.022]}
          scale={[56.346, 56.346, 104.352]}
        />
      </group>
      <mesh
        geometry={nodes.Root_Bottom.geometry}
        // material={nodes.Root_Bottom.material}
        material={material}
        position={[0.002, -0.187, 0]}
        rotation={[-Math.PI / 2, 0, 0.262]}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Root_Top.geometry}
        // material={nodes.Root_Top.material}
        material={material}
        position={[-0.009, -0.047, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </group>
  );
}
