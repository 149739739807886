/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useMaterials } from '../../../../stores/useMaterials';
import { AnimatedObject } from './AnimatedObject';

// useGLTF.preload("/models/Floating_Objects/01_Omen_BEE_01.glb");

export function Bee(props) {
  const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_BEE_01.glb');
  // const genericMaterial = useMaterials((state) => state.genericMaterial);

  return (
    <AnimatedObject {...props} animations={animations}>
      <group name="Scene" scale={0.95} position={[0, 0.2, 0]}>
        <group name="Bee" rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            name="Body"
            castShadow
            receiveShadow
            geometry={nodes.Body.geometry}
            material={props.material}
            position={[0, -0.275, -0.131]}
          />
          <mesh
            name="L"
            castShadow
            receiveShadow
            geometry={nodes.L.geometry}
            material={props.material}
            position={[0.025, 0.323, -0.131]}
            rotation={[0, -Math.PI / 4, Math.PI / 2]}
            scale={151.753}
          />
          <mesh
            name="R"
            castShadow
            receiveShadow
            geometry={nodes.R.geometry}
            material={props.material}
            position={[0, -0.275, -0.131]}
            rotation={[0, -Math.PI / 4, Math.PI / 2]}
            scale={151.753}
          />
        </group>
      </group>
    </AnimatedObject>
  );
}
