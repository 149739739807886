import style from './RoundButton.module.css';
import { useSounds } from '../../../stores/useSounds';
import { useMouse } from '../../../stores/useMouse';

export function RoundButton({ children, onClick, disabled = false }) {
  /*
   * properties
   */

  const isTouch = useMouse((state) => state.isTouch);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  const overHandler = (index) => {
    if (!isTouch) {
      playSound('uiHover');
    }
  };

  const clickHandler = () => {
    onClick();
    playSound('uiClick');
  };

  /*
   * visuals
   */

  return (
    <>
      <button className={style.button} disabled={disabled} onMouseEnter={overHandler} onClick={clickHandler}>
        {children}
      </button>
    </>
  );
}
