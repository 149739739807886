import { create } from 'zustand';

export const useSection = create((set) => ({
  segment: 'loader', // 'loader', 'intro', 'tree', 'modal', 'roots'

  activeSection: '',
  activeLevel: null,

  hoveredSection: '',
  gotoSection: false,

  setSegment: (segment) => set({ segment }),

  resetActiveSection: () => set({ activeSection: '', level: null }),
  setActiveSection: (activeSection) => set({ activeSection, activeLevel: 0 }),

  setActiveLevel: (activeLevel) => set({ activeLevel }),

  resetHoveredSection: () => set({ hoveredSection: '' }),
  setHoveredSection: (hoveredSection) => set({ hoveredSection }),

  clickSection: (section) => set({ gotoSection: section }),
  resetGotoSection: () => set({ gotoSection: false }),
}));
