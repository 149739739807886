/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { AnimatedObject } from './AnimatedObject';
import { useMaterials } from '../../../../stores/useMaterials';

// useGLTF.preload("/models/Floating_Objects/01_Omen_CROSS_01.glb");

export function Cross(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_CROSS_01.glb');
  // const genericMaterial = useMaterials((state) => state.genericMaterial);
  return (
    <AnimatedObject {...props} animations={animations}>
      <group name="Scene" scale={.85} position={[0, .2, 0]}>
        <group name="Cross" rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            name="Cross_2"
            castShadow
            receiveShadow
            geometry={nodes.Cross_2.geometry}
            material={props.material}
          />
        </group>
      </group>
    </AnimatedObject>
  );
}
