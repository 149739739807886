import { useEffect } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';
import style from './Logo.module.css';
import { useSection } from '../../../stores/useSection';
import { useCamera } from '../../../stores/useCamera';
import { useScene } from '../../../stores/useScene';
import { useSectionScroll } from '../../../stores/useSectionScroll';

export function Logo() {
  // console.log('LOGO render');
  const STATE_MACHINE_NAME = 'omen_logo';

  const { rive, RiveComponent } = useRive({
    src: '/riv/omen_logo.riv',
    artboard: STATE_MACHINE_NAME,
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const animHover = useStateMachineInput(rive, STATE_MACHINE_NAME, 'hover');

  const hideRoot = useScene((state) => state.hideRoot);
  const resetActiveSection = useSection((state) => state.resetActiveSection);
  const resetScrollTo = useSectionScroll((state) => state.resetScrollTo);
  const resetCamera = useCamera((state) => state.resetCamera);

  useEffect(() => {
    if (animHover) {
      animHover.value = true;
    }
  }, [animHover]);

  // const overHandler = () => {
  //   animHover.value = true;
  // };

  // const outHandler = () => {
  //   animHover.value = false;
  // };

  const clickHandler = () => {
    console.log('click');
    const isCameraTransitioning = useCamera.getState().isTransitioning;
    const isSceneTransitioning = useScene.getState().isTransitioning;

    if (!isCameraTransitioning && !isSceneTransitioning) {
      const activeSection = useSection.getState().activeSection;
      const isShowingRoot = useScene.getState().isShowingRoot;

      if (isShowingRoot) {
        hideRoot();
      } else if (activeSection) {
        resetActiveSection();
        resetScrollTo();
        resetCamera();
      }
    }
  };
  return (
    <>
      <div className={style.container}>
        {/* <RiveComponent onMouseEnter={overHandler} onMouseLeave={outHandler} /> */}
        <RiveComponent onClick={clickHandler} />
      </div>
    </>
  );
}
