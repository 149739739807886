import { useGLTF, useTexture } from '@react-three/drei';
import { useMaterials } from '../../../../../stores/useMaterials';
import * as THREE from 'three';

export function Coin() {
  /*
   * properties
   */

  const { nodes } = useGLTF('/models/Floating_Objects/01_Omen_Single_COIN_Static_01.glb');
  // const genericMaterial = useMaterials((state) => state.genericMaterial);

  const normalMap = useTexture('/textures/Coins_Normal_01-min.png');

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {/* <mesh>
        <cylinderGeometry args={[0.2, 0.2, 0.05, 32]} />
        <meshStandardMaterial color="red"/>
      </mesh> */}

      <group rotation={[Math.PI / 2, 0, 0]} scale={0.3}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_1_2.geometry}
          // material={nodes.Coin_1_2.material}
          // material={genericMaterial}
          position={[0.03, -0.15, 0]}
        >
          <meshStandardMaterial
            normalMap={normalMap}
            normalScale={new THREE.Vector2(10, 10)}
            color="#222222"
            metalness={0.9}
            fog={true}
            roughness={0.4}
          />
        </mesh>
      </group>
    </>
  );
}
