/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useMaterials } from '../../../../stores/useMaterials';
import { AnimatedObject } from './AnimatedObject';

// useGLTF.preload("/models/Floating_Objects/01_Omen_RAM_01.glb");

export function Ram(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/models/Floating_Objects/01_Omen_RAM_01.glb');
  // const genericMaterial = useMaterials((state) => state.genericMaterial);

  return (
    <AnimatedObject {...props} animations={animations}>
      <group name="Scene" scale={.85} position={[0, .7, 0]}>
        <group name="Ram" rotation={[1.563, -0.013, -0.009]}>
          <mesh name="Ram_2" castShadow receiveShadow geometry={nodes.Ram_2.geometry} material={props.material} />
          <mesh
            name="Ram_Jaw"
            castShadow
            receiveShadow
            geometry={nodes.Ram_Jaw.geometry}
            material={props.material}
            position={[0, -0.796, 0]}
          />
        </group>
      </group>
    </AnimatedObject>
  );
}
