import { useEffect, useRef, useState } from 'react';
import style from './SectionScroll.module.css';
import { useGesture } from '@use-gesture/react';
import { useSection } from '../stores/useSection';
import { useContent } from '../stores/useContent';
import { useCamera } from '../stores/useCamera';
import { useSectionScroll } from '../stores/useSectionScroll';

export function SectionScroll({ children }) {
  /*
   * properties
   */

  const container = useRef();
  const child = useRef();
  const activeSection = useSection((state) => state.activeSection);
  const setActiveLevel = useSection((state) => state.setActiveLevel);
  const scrollPct = useSectionScroll((state) => state.scrollPct);

  const isZooming = useCamera((state) => state.isZooming);

  const [height, setHeight] = useState(0);
  const amount = useRef(0);

  useGesture(
    {
      onScroll: (state) => {
        // const pct = state.offset[1] / window.innerHeight;
        const pct = state.offset[1] / (child.current.offsetHeight / amount.current);
        setActiveLevel(pct);
      },
    },
    {
      target: container,
    }
  );

  /*
   * hooks
   */

  useEffect(() => {
    if (isZooming && activeSection) {
      const content = useContent.getState().getContent(activeSection);
      amount.current = content.length - 1;
      if (content.length > 1) {
        setHeight(100 * amount.current);
      } else {
        setHeight(0);
      }
    } else {
      setHeight(0);
    }
  }, [activeSection, isZooming]);

  useEffect(() => {
    // console.log('SCROLL TO ' + scrollPct);
    if (scrollPct >= 0) {
      const el = container.current;
      const activeSection = useSection.getState().activeSection;
      const content = useContent.getState().getContent(activeSection);
      el.scrollTo({ top: (el.scrollHeight * scrollPct) / content.length, behavior: 'smooth' });
    }
  }, [scrollPct]);

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.scrollContainer}>
        <div className={style.canvasWrapper}>{children}</div>
        {height > 0 && <div ref={child} style={{ height: `${height}vh` }} className={style.inner} />}
      </div>
    </>
  );
}
