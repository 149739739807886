/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations, useTexture } from '@react-three/drei';
import { AnimatedObject } from './AnimatedObject';
import { useMaterials } from '../../../../stores/useMaterials';
import * as THREE from 'three';

// useGLTF.preload("/models/Floating_Objects/01_Omen_COINS_01.glb");

export function Coins(props) {
  const group = useRef();
  const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_COINS_01.glb');
  // const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_Single_COIN_Animated_01.glb');
  // const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_Single_COIN_Static_01.glb');

  // const genericMaterial = useMaterials((state) => state.genericMaterial);

  const normalMap = useTexture('/textures/Coins_Normal_01-min.png');

  const material = new THREE.MeshStandardMaterial({
    // side: THREE.BackSide,
    normalMap: normalMap,
    normalScale: new THREE.Vector2(10, 10),
    color: '#dedede',
    metalness: 0.78,
    roughness: 0.65,
  });

  return (
    <AnimatedObject {...props} animations={animations}>
      {/* <group rotation={[Math.PI / 2, 0, 0]} scale={1.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Coin_1_2.geometry}
          // material={nodes.Coin_1_2.material}
          material={props.material}
          position={[0.03, -0.15, 0]}
        />
      </group> */}

      {/* <group name="Scene">
        <group name="Coin" rotation={[Math.PI / 2, 0, 0]} scale={1.01}>
          <group name="Cluster">
            <group name="Coin_1" position={[0.03, -0.15, 0]}>
              <mesh
                name="Coin_1_2"
                castShadow
                receiveShadow
                geometry={nodes.Coin_1_2.geometry}
                // material={nodes.Coin_1_2.material}
                material={props.material}
              />
            </group>
          </group>
        </group>
      </group> */}

      <group name="Scene" scale={1.2}>
        <group name="Coins" rotation={[Math.PI / 2, 0, 0]}>
          <group name="Cluster">
            <group name="Coin_1" position={[-0.45, -0.15, 0]}>
              <mesh
                name="Coin_1_2"
                castShadow
                receiveShadow
                geometry={nodes.Coin_1_2.geometry}
                // material={props.material}
                material={props.red ? props.material : material}
              />
            </group>
            <group name="Coin_2" position={[0, 0, -0.15]}>
              <mesh
                name="Coin_2_2"
                castShadow
                receiveShadow
                geometry={nodes.Coin_2_2.geometry}
                // material={props.material}
                material={props.red ? props.material : material}
              />
            </group>
            <group name="Coin_3" position={[0.45, 0, 0]}>
              <mesh
                name="Coin_3_2"
                castShadow
                receiveShadow
                geometry={nodes.Coin_3_2.geometry}
                // material={props.material}
                material={props.red ? props.material : material}
              />
            </group>
          </group>
        </group>
      </group>
    </AnimatedObject>
  );
}
