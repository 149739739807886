import { Intro } from '../../segments/intro/Intro';
import { Loader } from '../../segments/loader/Loader';
import style from './Home.module.css';

import { useEffect } from 'react';

export function Home() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
    
      <Loader />
      <Intro />
    </>
  );
}
