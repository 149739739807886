import { useEffect, useRef } from 'react';
import { useScene } from '../../../stores/useScene';
import { MainScene } from '../../components/mainScene/MainScene';
import { Root } from '../../components/root/Root';
import gsap from 'gsap';
import { SceneMask } from './SceneMask';

export function Home() {
  /*
   * properties
   */

  const root = useRef();
  const container = useRef();
  const main = useRef();
  const isShowingRoot = useScene((state) => state.isShowingRoot);
  const startTransitioning = useScene((state) => state.startTransitioning);
  const stopTransitioning = useScene((state) => state.stopTransitioning);
  const ratio = 1 / 1;

  const pos = useRef();
  const pct = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = () => {
    const windowRatio = window.innerWidth / window.innerHeight;

    if (windowRatio > ratio) {
      pos.current = 7.55 * 0.4;
    } else {
      pos.current = (7.55 * 0.35) / windowRatio;
    }
    root.current.position.y = -pos.current;
    setPosition();
  };

  const setPosition = () => {
    container.current.position.y = pos.current * pct.current;
  };

  useEffect(() => {
    main.current.visible = true;
    root.current.visible = true;

    let value = !isShowingRoot ? 0 : 1;

    if (container.current) {
      startTransitioning();

      gsap.to(pct, {
        current: value,
        duration: 1.5,
        onUpdate: setPosition,
        ease: 'power4.inOut',
        onComplete: completeHandler,
      });
    }
  }, [isShowingRoot]);

  const completeHandler = () => {
    stopTransitioning();
    main.current.visible = !isShowingRoot;
    root.current.visible = isShowingRoot;
  };

  /*
   * visuals
   */

  return (
    <>
      <group ref={container} position={[0, 0, 0]}>
        <group ref={main}>
          <MainScene />
        </group>

        <group ref={root}>
          <Root />
        </group>
      </group>
    </>
  );
}
