import { Howl, Howler } from 'howler';
import { create } from 'zustand';

let sounds = {
  uiClick: new Howl({ src: ['/sounds/UI_MISC_hover.mp3'] }),
  mainLoop: new Howl({ loop: true, src: ['/sounds/main_loop_20sec.mp3'] }),
};

let inited = false;

export function loadSounds() {
  // console.log('useSounds :: loadSounds');
  if (!inited) {
    inited = true;

    sounds = {
      // uiClick: new Howl({ src: ['/sounds/UI_click.mp3'] }),
      uiClick: new Howl({ src: ['/sounds/UI_MISC_hover.mp3'] }),
      uiBack: new Howl({ src: ['/sounds/UI_back.mp3'] }),
      // uiHover: new Howl({ src: ['/sounds/UI_hover.mp3'] }),
      uiHover: new Howl({ src: ['/sounds/UI_WOOD_hover.mp3'] }),

      // introBegin: new Howl({ src: ['/sounds/Intro_Begin.mp3'] }),
      introEnter: new Howl({ src: ['/sounds/Intro_Enter.mp3'] }),
      introNavigate: new Howl({ src: ['/sounds/Intro_Navigate.mp3'] }),

      transition: new Howl({ src: ['/sounds/Transition.mp3'] }),

      choir1: new Howl({ loop: true, src: ['/sounds/chior_loop_1.mp3'] }),
      choir2: new Howl({ loop: true, src: ['/sounds/chior_loop_2.mp3'] }),
      choir3: new Howl({ loop: true, src: ['/sounds/chior_loop_3.mp3'] }),
      choir4: new Howl({ loop: true, src: ['/sounds/chior_loop_4.mp3'] }),
      // choir5: new Howl({ loop: true, src: ['/sounds/chior_loop_5.mp3'] }),
      choir5: new Howl({ loop: true, src: ['/sounds/chior_loop_6.mp3'] }),
      choir6: new Howl({ loop: true, src: ['/sounds/chior_loop_7.mp3'] }),

      mainLoop: new Howl({ loop: true, src: ['/sounds/main_loop_20sec.mp3'] }),
      treeLoop: new Howl({ loop: true, src: ['/sounds/tree_open_loop_20sec.mp3'] }),
      rootsLoop: new Howl({ loop: true, src: ['/sounds/tree_roots_loop_20sec.mp3'] }),
    };
  }
}

export const useSounds = create((set) => ({
  soundOn: true,

  setSoundOn: (soundOn) => set({ soundOn }),

  playSound: (name) => {
    // console.log('playSound :: ' + name);
    let sound;

    if (sounds && Howler._audioUnlocked) {
      sound = sounds[name];
      if (sound) {
        sound.play();
      }
    }

    return sound;
  },

  getSound: (name) => {
    if (sounds && Howler._audioUnlocked) {
      const sound = sounds[name];
      // sound.on('fade', function () {
      //   if (sound.volume() === 0) {
      //     sound.stop();
      //   }
      // });
      return sound;
    }
    return null;
  },
}));
