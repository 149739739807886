import { useFrame } from '@react-three/fiber';
import style from './MainScroll.module.css';
import { useEffect, useRef, useState } from 'react';
import { useMainScroll } from '../stores/useMainScroll';
import { useCamera } from '../stores/useCamera';
import { useGesture } from '@use-gesture/react';

// let autoScrollSpeed = 0;
// let autoScroll = false;

let pct = 0;
let endPct = 0;
let scrollSpeed = 0;
let endScrollSpeed = 0;

// let toTopSwitch = false;
// let toBottomSwitch = false;

export function MainScroll({ children }) {
  /*
   * properties
   */

  const container = useRef();
  const setPct = useMainScroll((state) => state.setPct);
  const setScrollSpeed = useMainScroll((state) => state.setScrollSpeed);
  const isZooming = useCamera((state) => state.isZooming);

  useGesture(
    {
      onDrag: (state) => {
        if (state.last && state.velocity[0] > 0.3) {
          let scrollTo;
          if (state.direction[0] < 0) {
            // console.log('swipe left');
            scrollTo = endPct > 0.5 || endPct < 0.02 ? 0.5 : 0.01;

            if (endPct < 0.02) {
              endPct = pct = 0.99;
            }
          } else {
            // console.log('swipe right');
            scrollTo = endPct < 0.5 ? 0.5 : .99;
          }

          const element = container.current;
          element.scrollTop = (element.scrollHeight - element.clientHeight) * scrollTo;
        }
      },
    },
    {
      target: container,
    }
  );

  /*
   * hooks
   */

  useEffect(() => {
    const intervalId = setInterval(loop, 30);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const scrollHandler = (event) => {
    if (!isZooming) {
      const element = container.current;
      endPct = element.scrollTop / (element.scrollHeight - element.clientHeight);

      // console.log(element.scrollTop, endPct);

      if (endPct == 1) {
        // console.log('TO TOP');
        endPct = 0.01;
        pct -= 1;
        element.scrollTop = (element.scrollHeight - element.clientHeight) * endPct;
      }

      if (endPct <= 0) {
        // console.log('TO BOTTOM');
        endPct = 0.99;
        pct += 1;
        element.scrollTop = (element.scrollHeight - element.clientHeight) * endPct;
      }
    }
  };

  const loop = () => {
    endScrollSpeed = -(endPct - pct) * 50;
    scrollSpeed += (endScrollSpeed - scrollSpeed) * 0.05;

    setScrollSpeed(scrollSpeed);

    pct += (endPct - pct) * 0.1;

    if (pct > 0.99) {
      pct = 1;
    }

    setPct(pct);
  };

  /*
   * visuals
   */

  return (
    <div ref={container} className={style.scrollContainer} onScroll={scrollHandler}>
      {children}
      <div className={style.child} />
    </div>
  );
}
