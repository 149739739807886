import { Icon } from '../../component/Icon';
import { RoundButton } from '../../component/buttons/RoundButton';
import { StandardButton } from '../../component/buttons/StandardButton';
import style from './UI.module.css';

export function UI() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.container}>
        <h1 className="text-h1">kaka</h1>

        {/* <div className={style.iconContainer}>
          <Icon svg="loop" />
        </div>

        <div className={style.iconContainer}>
          <Icon svg="cross" />
        </div> */}

        <RoundButton>III</RoundButton>

        <RoundButton>
          <Icon svg="loop" />
        </RoundButton>

        <StandardButton><Icon svg="cross" /> Go Back</StandardButton>
      </div>
    </>
  );
}
