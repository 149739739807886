import style from './StandardButton.module.css';
import { useSounds } from '../../../stores/useSounds';
import { useMouse } from '../../../stores/useMouse';

export function StandardButton({ children, onClick, disabled = false, responsive = true }) {
  /*
   * properties
   */

  const isTouch = useMouse((state) => state.isTouch);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  const overHandler = (index) => {
    if (!isTouch) {
      playSound('uiHover');
    }
  };

  const clickHandler = () => {
    onClick && onClick();
    playSound('uiClick');
  };

  /*
   * visuals
   */

  return (
    <>
      <button
        disabled={disabled}
        className={`${style.button} ${responsive ? style.buttonResponsive : style.buttonFixed}`}
        onMouseEnter={overHandler}
        onClick={clickHandler}
      >
        {children}
      </button>
    </>
  );
}
