import { MeshDiscardMaterial, shaderMaterial } from '@react-three/drei';
import { useFrame, extend } from '@react-three/fiber';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';
import { useEffect, useRef, useState } from 'react';
import { useMainScroll } from '../../../stores/useMainScroll';
import gsap from 'gsap';
import { useSection } from '../../../stores/useSection';

const HoleMaterial = shaderMaterial(
  {
    uTime: 0,
    uAlpha: 0,
    uResolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
  },
  vertexShader,
  fragmentShader
);

extend({ HoleMaterial });

export function Hole({ onOver, onOut }) {
  /*
   * properties
   */

  const isHoveringHole = useMainScroll((state) => state.isHoveringHole);
  const setIsHoveringHole = useMainScroll((state) => state.setIsHoveringHole);
  const hoveredSection = useSection((state) => state.hoveredSection);

  const mesh = useRef();
  const holeMaterial = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    if (hoveredSection) {
      outHandler();
    }
  }, [hoveredSection]);

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    holeMaterial.current.uniforms.uTime.value = time * 0.6;

    const scrollPct = useMainScroll.getState().pct;
    // console.log(scrollPct);

    if (isHoveringHole && (scrollPct < 0.35 || scrollPct > 0.65)) {
      outHandler();
    }
  });

  const overHandler = () => {
    // console.log('OVER');
    if (!isHoveringHole) {
      onOver();
      gsap.to(holeMaterial.current.uniforms.uAlpha, { value: 1, duration: 0.3, ease: 'power1.in' });
      setIsHoveringHole(true);
    }
  };

  const outHandler = () => {
    // console.log('OUT');
    if (isHoveringHole) {
      onOut();
      gsap.to(holeMaterial.current.uniforms.uAlpha, { value: 0, duration: 0.3, ease: 'power1.out' });
      setIsHoveringHole(false);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh
        ref={mesh}
        position={[0, 0.58, -0.6]}
        rotation={[0, 3.14, 0]}
        onPointerOver={overHandler}
        // onPointerOut={outHandler}
        onClick={overHandler}
      >
        {/* <sphereGeometry args={[0.4, 10]} /> */}
        <planeGeometry args={[0.5, 0.5]} />
        {/* <meshBasicMaterial color="red" transparent opacity={0.5} /> */}
        <MeshDiscardMaterial />
      </mesh>
      <mesh ref={mesh} position={[0, 0.65, -1]} rotation={[0, 3.14, 0]}>
        <planeGeometry args={[7, 7]} />
        <holeMaterial ref={holeMaterial} transparent depthWrite={false} />
      </mesh>
    </>
  );
}
