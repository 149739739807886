import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Html, MeshDiscardMaterial, useCursor } from '@react-three/drei';
import style from './Object.module.css';
import { useCamera } from '../../../stores/useCamera';
import * as THREE from 'three';
import { useSection } from '../../../stores/useSection';
import { Icon } from '../../../app2D/component/Icon';
import { Panels } from './panel/Panels';
import { ObjectInner } from './ObjectInner';
import { useLight } from '../../../stores/useLight';
import { useFrame } from '@react-three/fiber';
import { useMainScroll } from '../../../stores/useMainScroll';
import { useSounds } from '../../../stores/useSounds';
import { useMouse } from '../../../stores/useMouse';
import { useScene } from '../../../stores/useScene';

export function Object({ index, section, content }) {
  
  /*
   * properties
   */

  const startPositions = [-0.15, 0, 0.15, 0.5 - 0.15, 0.5, 0.5 + 0.15];
  const startPos = startPositions[index];

  const isTouch = useMouse((state) => state.isTouch);
  const playSound = useSounds((state) => state.playSound);
  const sound = useRef(null);

  const setCamera = useCamera((state) => state.setCamera);
  const resetLightPosition = useLight((state) => state.resetLightPosition);
  const setLightPosition = useLight((state) => state.setLightPosition);
  const switchOn = useLight((state) => state.switchOn);
  const switchOff = useLight((state) => state.switchOff);

  const setActiveSection = useSection((state) => state.setActiveSection);
  const activeSection = useSection((state) => state.activeSection);
  const segment = useSection((state) => state.segment);
  const resetHoveredSection = useSection((state) => state.resetHoveredSection);
  const setHoveredSection = useSection((state) => state.setHoveredSection);
  const gotoSection = useSection((state) => state.gotoSection);
  const resetGotoSection = useSection((state) => state.resetGotoSection);
  
  const showSection = useScene((state) => state.showSection);
  const resetShowSection = useScene((state) => state.resetShowSection);
  const isShowingRoot = useScene((state) => state.isShowingRoot);

  const wrapper = useRef();

  const startPct = useRef(1);
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const [shown, setShown] = useState(false);
  const [started, setStarted] = useState(false);
  useCursor(hovered);

  /*
   * hooks
   */

  useEffect(() => {
    if (!shown && segment === 'tree') {
      const delay = 1.5 + index * 0.2;

      // gsap.set(wrapper.current.scale, { x: 0, y: 0, z: 0 });
      gsap.to(wrapper.current.scale, { x: 1, z: 1, y: 1, delay, duration: 1, ease: 'power4.out' });
      gsap.to(startPct, {
        current: 0,
        delay,
        duration: 3,
        ease: 'power2.out',
        // onUpdate: () => {
        //   setposition(startPos - 0.3 * startPct.current);
        // },
        onComplete: () => {
          setStarted(true);
          setShown(true);
        },
      });

      setposition(startPos);
    }
  }, [segment]);

  // const setposition = (pct) => {
  const setposition = () => {
    const scrollSpeed = useMainScroll.getState().scrollSpeed;

    const pos = startPos - 0.3 * startPct.current + scrollSpeed * 0.05;
    const p = 0 - pos * 6.2832;

    const radius = 2;
    const x = -Math.cos(p);
    const y = Math.sin(p * radius) + 1.65;
    const z = Math.sin(p);

    wrapper.current.position.set(x * 3, y * 1.4 + 0.4, z + 0.5);
  };

  useEffect(() => {
    if (section === showSection) {
      // console.log('jazeker');
      resetShowSection();

      setTimeout(onClick, 500);
    }
  }, [showSection]);

  const onClick = () => {
    const isActive = Boolean(!content?.link)

    if (!active && activeSection === '' && !useScene.getState().isShowingRoot) {
      // if (section === 'Ram') {
      //   window.open('https://omen.ada-anvil.io/en/');
      // }

      // const caption = useContent.getState().getCaption(section);

      if (content?.link) {
        window.open(content.link);
      }

      if (isActive) {
        if (sound.current) {
          sound.current.fade(sound.current.volume(), 0, 700);
        }

        playSound('uiClick');
        playSound('transition');

        const pos = new THREE.Vector3();
        pos.copy(wrapper.current.position);
        pos.x += index < 3 ? -0.95 : 0.95;
        setCamera(pos);
        setActiveSection(section);

        setActive(true);
        setHovered(false);
        resetHoveredSection();

        resetLightPosition();
        switchOn();
      } else if (isTouch) {
        onOver();
      }
    }
  };

  const onOver = () => {
    // console.log('onOver');
    if (activeSection === '') {
      if (!sound.current) {
        sound.current = useSounds.getState().getSound('choir' + (index + 1));
        sound.current.volume(0);
      }
      sound.current.play();
      sound.current.fade(sound.current.volume(), 0.5, 500);

      setHovered(true);

      setHoveredSection(section);

      const pos = new THREE.Vector3();
      pos.copy(wrapper.current.position);

      setLightPosition(pos);
      switchOff();
    }
  };

  const onOut = () => {
    if (sound.current) {
      sound.current.fade(sound.current.volume(), 0, 500);
    }
    setHovered(false);
    resetHoveredSection();
    resetLightPosition();
    switchOn();
  };

  useEffect(() => {
    if (activeSection === '' && active) {
      setActive(false);
      setHovered(false);
    }
  }, [activeSection]);

  useEffect(() => {
    // console.log('isShowingRoot: ' + isShowingRoot)
    // console.log('hovered: ' + hovered)
    // console.log('active: ' + active)
    if (isShowingRoot && hovered) {
      // console.log('switch off');
      onOut()
    }
  }, [isShowingRoot]);

  useFrame(() => {
    setposition();
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} scale={0}>
        {shown && (
          <mesh onPointerOver={onOver} onPointerOut={onOut} onClick={onClick} onPointerMissed={onOut}>
            <boxGeometry args={[1, 1, 0.1]} />
            <MeshDiscardMaterial />
          </mesh>
        )}

        <ObjectInner index={index} section={section} active={active} hovered={hovered} started={started} />

        <Panels allPanelContent={content} content={content?.slices} index={index} section={section} />
      </group>
    </>
  );
}
