import { Bloom, ChromaticAberration, EffectComposer, GodRays, Noise, Vignette } from '@react-three/postprocessing';
import { BlendFunction } from 'postprocessing';
// import { folder, useControls } from 'leva';
import { useEffect, useRef, useState } from 'react';
import { useMouse } from '../../stores/useMouse';
import { Circle, GradientTexture, GradientType } from '@react-three/drei';
import { useSection } from '../../stores/useSection';
import gsap from 'gsap';
import { useSize } from '../../stores/useSize';

export function PostProcessing({ dpr }) {
  /*
   * properties
   */

  const sun = useRef();
  const sunMaterial = useRef();
  const [showRays, setShowRays] = useState(false);

  const activeSection = useSection((state) => state.activeSection);
  const demoMode = useSize((state) => state.demoMode);

  // const config = useControls('Postprocessing', {
  //   sun: folder({
  //     sunColor: { value: '#303030', label: 'config' },
  //   }),
  //   rays: folder({
  //     density: { value: 0.92, min: 0, max: 5, step: 0.01 },
  //     decay: { value: 0.96, min: 0, max: 1, step: 0.01 },
  //     weight: { value: 2.0, min: 0, max: 5, step: 0.01 },
  //     exposure: { value: 1.0, min: 0, max: 1, step: 0.01 },
  //     blur: { value: true },
  //   }),
  //   noise: folder({
  //     noiseEnabled: { value: true, label: 'enabled' },
  //     opacity: { value: 0.9, min: 0, max: 2 },
  //   }),
  //   bloom: folder({
  //     bloomEnabled: true,
  //     intensity: { value: 0.6, min: 0, max: 5, step: 0.01 },
  //     luminanceThreshold: { value: 0.5, min: 0, max: 2, step: 0.01 },
  //     luminanceSmoothing: { value: 0, min: 0, max: 2, step: 0.01 },
  //     mipmapBlur: true,
  //   }),
  // });

  const config = {
    sunColor: '#303030',

    density: 0.92,
    decay: 0.96,
    weight: 2.0,
    exposure: 1.0,
    blur: true,

    noiseEnabled: true,
    opacity: 0.6,

    bloomEnabled: true,
    intensity: 0.6,
    luminanceThreshold: 0.5,
    luminanceSmoothing: 0,
    mipmapBlur: true,
  };

  useEffect(() => {
    // temp uitgezet
    setShowRays(true);
  }, []);

  useEffect(() => {
    // console.log('activeSection: ' + activeSection);
    // console.log(sunMaterial.current.opacity);
    if (activeSection) {
      // console.log('to 0');
      gsap.to(sunMaterial.current, { opacity: 0, duration: 0.5, ease: 'power1.out' });
    } else {
      // console.log('to 1');
      gsap.to(sunMaterial.current, { opacity: 1, delay: 1, duration: 0.5, ease: 'power1.in' });
    }
  }, [activeSection]);

  /*
   * visuals
   */

  return (
    <>
      <EffectComposer key={dpr}>
        <Circle args={[1.5, 20]} ref={sun} rotation={[0.5, 0, 0]} position={[-0.4, 4.5, -10]}>
          <meshBasicMaterial ref={sunMaterial} fog={false} transparent>
            <GradientTexture
              stops={[0, 1]} // As many stops as you want
              colors={[config.sunColor, '#000000']} // Colors need to match the number of stops
              size={1024} // Size (height) is optional, default = 1024
              width={1024} // Width of the canvas producing the texture, default = 16
              type={GradientType.Radial} // The type of the gradient, default = GradientType.Linear
              innerCircleRadius={0} // Optional, the radius of the inner circle of the gradient, default = 0
              outerCircleRadius={'auto'} // Optional, the radius of the outer circle of the gradient, default = auto
            />
          </meshBasicMaterial>
        </Circle>

        {showRays && (
          <GodRays
            sun={sun.current}
            density={config.density} // The density of the light rays.
            decay={config.decay} // An illumination decay factor.
            weight={config.weight} // A light ray weight factor.
            exposure={config.exposure} // A constant attenuation coefficient.
            blur={config.blur}
          />
        )}

        {config.bloomEnabled && (
          <Bloom
            intensity={config.intensity}
            luminanceThreshold={config.luminanceThreshold} // luminance threshold. Raise this value to mask out darker elements in the scene.
            luminanceSmoothing={config.luminanceSmoothing} // smoothness of the luminance threshold. Range is [0, 1]
            mipmapBlur={config.mipmapBlur} // Enables or disables mipmap blur.
          />
        )}

        {config.noiseEnabled && !demoMode && (
          <Noise
            premultiply={true} // enables or disables noise premultiplication
            opacity={config.opacity}
            blendFunction={BlendFunction.NORMAL} // blend mode
          />
        )}
      </EffectComposer>
    </>
  );
}
