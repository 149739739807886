import style from './SmallRoundButton.module.css';
import { useSounds } from '../../../stores/useSounds';
import { useMouse } from '../../../stores/useMouse';

export function SmallRoundButton({ children, onClick, single, highlight = false }) {
  /*
   * properties
   */

  const isTouch = useMouse((state) => state.isTouch);
  const playSound = useSounds((state) => state.playSound);

  /*
   * hooks
   */

  const overHandler = (index) => {
    if (!isTouch) {
      playSound('uiHover');
    }
  };

  const clickHandler = () => {
    onClick();
    playSound('uiClick');
  };

  /*
   * visuals
   */

  return (
    <>
      <button
        className={`${style.button} ${highlight ? style.highlight : ''} ${single ? style.single : ''}`}
        onMouseEnter={overHandler}
        onClick={clickHandler}
      >
        {children}
      </button>
    </>
  );
}
