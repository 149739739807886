import style from './Icon.module.css';

import Cross from './svg/cross.svg?react';
import Loop from './svg/loop.svg?react';
import Connect from './svg/connect.svg?react';
import Menu from './svg/menu.svg?react';
import Sound from './svg/sound.svg?react';
import Arrow from './svg/arrow.svg?react';

export function Icon({ svg = 'cross' }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {/* <div className={style.icon}> */}
      {svg === 'loop' && <Loop />}
      {svg === 'cross' && <Cross />}
      {svg === 'connect' && <Connect />}
      {svg === 'menu' && <Menu />}
      {svg === 'sound' && <Sound />}
      {svg === 'arrow' && <Arrow />}
      {/* </div> */}
    </>
  );
}
