import React, { useEffect, useRef } from 'react';
import { useAnimations } from '@react-three/drei';
import * as THREE from 'three';

// export function AnimatedObject({ children, animations, hovered, active, testAnimation, testRepeat }) {
export function AnimatedObject({ children, animations, hovered, active, started }) {
  const group = useRef();
  const { names, actions } = useAnimations(animations, group);
  const activeAction = useRef();

  useEffect(() => {
    // console.log("AnimatedObject: ", active, hovered, started)

    // const index = hovered || started ? 2 : active ? 1 : 0;

    if (hovered) {
      const index = 2;

      //0 = idle low
      //1 = idle high
      //2 = animation

      activeAction.current = actions[names[index]];

      if (activeAction.current) {
        activeAction.current.loop = THREE.LoopOnce;
      }

      if (activeAction.current) {
        activeAction.current.loop = THREE.LoopOnce;
        if (activeAction.current.time === 0 || activeAction.current.time === activeAction.current._clip.duration) {
          activeAction.current.reset().play();
        }
      }
    }
  }, [hovered]);

  return (
    <group ref={group} scale={[0.25, 0.25, 0.25]} rotation={[0.3, 3.14, 0]} dispose={null}>
      {children}
    </group>
  );
}
