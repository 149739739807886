import { useFrame } from '@react-three/fiber';
import { BigText } from '../text/BigText';
import { useSection } from '../../../stores/useSection';
import { useEffect, useRef, useState } from 'react';

export function Intro() {
  /*
   * properties
   */

  // const wrapper = useRef();
  const segment = useSection((state) => state.segment);
  // const shown = useRef(true);
  const [showText, setShowText] = useState(false);
  const [shown, setShown] = useState(true);

  /*
   * hooks
   */

  useEffect(() => {
    if (!showText && segment === 'intro') {
      // console.log('showIntro');
      setShowText(true);
    } else if (showText && segment !== 'intro') {
      setShowText(false);
      // console.log('hideIntro');
      if (shown) {
        setTimeout(() => {
          setShown(false);
        }, 2000);
      }
    }
  }, [segment]);

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <group position={[0, 1.9, 2]}>
          <BigText text="One Day" position={[0.5, 0.7, 0]} show={showText} delay={0.5} />
          <BigText text="at THE" position={[-0.5, 0, 0]} show={showText} delay={0.75} />
          <BigText text="Time..." position={[1, -0.7, 0]} show={showText} delay={1.0} />
        </group>
      )}
    </>
  );
}
