import { shaderMaterial, useTexture } from '@react-three/drei';
import { useFrame, extend } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import * as THREE from 'three';
import { useSize } from '../../../stores/useSize';
import { useSection } from '../../../stores/useSection';
import gsap from 'gsap';

const amount = 300;

const InfiniteMaterial = shaderMaterial(
  {
    uTime: 0,
    uAlpha: 1,
    uDpr: 1,
    uColor: new THREE.Color(1.0, 1.0, 1.0),
    uTexture: null,
    uAmount: 0,
    uResolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
  },
  vertexShader,
  fragmentShader
);

extend({ InfiniteMaterial });

export function Loop() {
  /*
   * properties
   */
  const activeSection = useSection((state) => state.activeSection);
  const segment = useSection((state) => state.segment);
  const shown = useRef(false);

  const infiniteMaterial = useRef();
  const mesh = useRef();
  const dpr = useSize((state) => state.dpr);

  const texture = useTexture('/textures/blurredPoint-min.png');

  const points = useMemo(() => {
    const p = new Array(amount * 3);
    for (let i = 0; i < amount; i++) {
      // p[i * 3 + 0] = -width * 0.5 + Math.random() * width;
      // p[i * 3 + 1] = -height * 0.5 + Math.random() * height;
      // p[i * 3 + 2] = -depth * 0.5 + Math.random() * depth;

      p[i * 3 + 0] = 0;
      p[i * 3 + 1] = 0;
      p[i * 3 + 2] = 0;
    }

    return new Float32Array(p);
  }, [amount]);

  const speed = useMemo(() => {
    const s = new Array(amount);
    for (let i = 0; i < amount; i++) {
      s[i] = 0.75 + Math.random() * 0.5;
    }

    return new Float32Array(s);
  }, [amount]);

  const indexes = useMemo(() => {
    const a = new Array(amount);
    for (let i = 0; i < amount; i++) {
      a[i] = i;
    }

    return new Float32Array(a);
  }, [amount]);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const resizeHandler = () => {
    infiniteMaterial.current.uniforms.uResolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight);
  };

  useEffect(() => {
    infiniteMaterial.current.uniforms.uDpr.value = dpr;
  }, [dpr]);

  useEffect(() => {
    if (shown.current) {
      gsap.killTweensOf(infiniteMaterial.current.uniforms.uAlpha);
      if (activeSection) {
        gsap.to(infiniteMaterial.current.uniforms.uAlpha, { value: 0, duration: 1, ease: 'power1.out' });
      } else {
        gsap.to(infiniteMaterial.current.uniforms.uAlpha, { value: 1, delay: 1, duration: 1, ease: 'power1.out' });
      }
    }
  }, [activeSection]);

  useEffect(() => {
    if (!shown.current && segment === 'tree') {
      shown.current = true;
      gsap.to(infiniteMaterial.current.uniforms.uAlpha, { value: 1, delay: 1, duration: 5, ease: 'power1.out' });

      gsap.set(mesh.current.scale, { x: 0.85, y: 0.85, z: 1 });
      gsap.to(mesh.current.scale, { x: 1, y: 1, z: 1, delay: 1, duration: 3, ease: 'power4.out' });
    }
  }, [segment]);

  useFrame((state) => {
    const time = state.clock.elapsedTime;
    infiniteMaterial.current.uniforms.uTime.value = time * 0.3;
  });

  /*
   * visuals
   */

  return (
    <>
      <points ref={mesh} position={[-0.3, 2.75, 0]} rotation={[0, -0.1, 0]} frustumCulled={false}>
        <bufferGeometry>
          <bufferAttribute attach={'attributes-position'} args={[points, 3, false]} />
          <bufferAttribute attach={'attributes-aIndex'} args={[indexes, 1, false]} />
          <bufferAttribute attach={'attributes-aSpeed'} args={[speed, 1, false]} />

          {/* <bufferAttribute attach={'attributes-aRnd'} args={[rnd, 1, false]} /> */}
          {/* <bufferAttribute attach={'attributes-aSize'} args={[sizes, 1, false]} /> */}
        </bufferGeometry>
        <infiniteMaterial
          ref={infiniteMaterial}
          uColor={'#ffffff'}
          uTexture={texture}
          uAlpha={0}
          uAmount={amount}
          transparent={true}
          depthWrite={false}
        />
      </points>
    </>
  );
}
