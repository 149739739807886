import { useEffect, useRef, useState } from 'react';
import { Howler } from 'howler';
import buttonStyle from './RoundButton.module.css';
import soundStyle from './SoundButton.module.css';
import gsap from 'gsap';
import { useSounds } from '../../../stores/useSounds';
import { useMouse } from '../../../stores/useMouse';

import { Icon } from '../Icon';

export function SoundButton() {
  /*
   * properties
   */

  const isTouch = useMouse((state) => state.isTouch);
  const playSound = useSounds((state) => state.playSound);
  const soundOn = useSounds((state) => state.soundOn);
  const setSoundOn = useSounds((state) => state.setSoundOn);

  // let [soundOn, setSoundOn] = useState(true);
  let [switchedOff, setSwitchedOff] = useState(false);

  let volume = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    document.addEventListener('visibilitychange', tabSwitch);

    return () => {
      document.removeEventListener('visibilitychange', tabSwitch);
    };
  });

  const tabSwitch = () => {
    if (document.visibilityState === 'visible') {
      Howler.mute(false);
    } else {
      Howler.mute(true);
    }
  };

  const overHandler = (index) => {
    if (!isTouch) {
      playSound('uiHover');
    }
  };

  const clickHandler = () => {
    playSound('uiClick');
    setSoundOn(!soundOn);
  };

  useEffect(() => {
    // playSound('uiClick');

    gsap.killTweensOf(volume);

    //not async
    setSwitchedOff(!soundOn);

    // console.log('switchedOff: ' + switchedOff);

    if (!soundOn) {
      gsap.to(volume, { current: 0, duration: 0.5, onUpdate: setVolume });
    } else {
      gsap.to(volume, { current: .55, duration: 0.5, onUpdate: setVolume });
    }

    //async!
  }, [soundOn]);

  const setVolume = () => {
    Howler.volume(volume.current);
  };

  /*
   * visuals
   */

  return (
    <>
      <button className={buttonStyle.button} onMouseEnter={overHandler} onClick={clickHandler}>
        <div className={`${soundStyle.iconWrapper} ${switchedOff ? soundStyle.switchedOff : ''}`}>
          <Icon svg="sound" />
        </div>
      </button>
    </>
  );
}
