import { useEffect, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { MathUtils } from 'three';
import gsap from 'gsap';

import { useFrame } from '@react-three/fiber';
import { useMouse } from '../../stores/useMouse';
import { useCamera } from '../../stores/useCamera';
import { useSection } from '../../stores/useSection';
import { useScene } from '../../stores/useScene';

export function CameraManager() {
  /*
   * properties
   */

  const cameraPosition = useCamera((state) => state.cameraPosition);
  const startZooming = useCamera((state) => state.startZooming);
  const stopZooming = useCamera((state) => state.stopZooming);
  const startTransitioning = useCamera((state) => state.startTransitioning);
  const stopTransitioning = useCamera((state) => state.stopTransitioning);

  const isShowingRoot = useScene((state) => state.isShowingRoot);

  const wrapper = useRef();
  const camera = useRef();
  const isZoomed = useRef(false);
  const zoom = useRef(8);
  const ratio = 1 / 1;

  // state properties

  /*
   * hooks
   */

  // set camera FOV after resizing
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = (e) => {
    const fov = 50;
    const cam = camera.current;

    if (cam.aspect > ratio) {
      //window too wide
      cam.fov = fov;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
      const rat = cam.aspect / ratio;
      cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
    }

    calculateZoom();
    gsap.killTweensOf(camera.current.position);
    gsap.set(camera.current.position, { z: zoom.current });
  };

  const calculateZoom = () => {
    if (isZoomed.current) {
      const ratio = window.innerWidth / window.innerHeight;
      const extra = Math.max(0, 1 - ratio);

      zoom.current = 2.4 - extra * 1;
    } else {
      zoom.current = 8;
    }
  };

  useEffect(() => {
    startTransitioning();
    if (cameraPosition) {
      isZoomed.current = true;
      calculateZoom();

      const activeLevel = 0;
      gsap.to(wrapper.current.position, {
        duration: 1.4,
        x: cameraPosition.x,
        y: cameraPosition.y,
        z: cameraPosition.z - activeLevel * 2.5,
        ease: 'power1.out',
      });
      gsap.to(camera.current.position, {
        duration: 2.1,
        z: zoom.current,
        ease: 'power4.inOut',
        onComplete: () => {
          if (!useScene.getState().isShowingRoot) {
            startZooming();
            stopTransitioning();
          }
        },
      });
      // gsap.to(camera.current.rotation, { x: 0, duration: 2.1, ease: 'power4.inOut', onComplete: startZooming });
    } else {
      isZoomed.current = false;
      calculateZoom();
      stopZooming();

      gsap.to(wrapper.current.position, { x: 0, y: 1, z: 0, duration: 1.4, ease: 'power1.inOut' });
      gsap.to(camera.current.position, {
        z: zoom.current,
        duration: 1.8,
        ease: 'power4.inOut',
        onComplete: stopTransitioning,
      });
      // gsap.to(camera.current.rotation, { x: 0.15, duration: 1.8, ease: 'power4.inOut' });
    }
  }, [cameraPosition]);

  useEffect(() => {
    if (cameraPosition || isShowingRoot) {
      gsap.to(camera.current.rotation, { x: 0, duration: 1, ease: 'sine.inOut' });
    } else {
      gsap.to(camera.current.rotation, { x: 0.15, duration: 1, ease: 'sine.inOut' });
    }
  }, [cameraPosition, isShowingRoot]);

  //move camera on mouse move
  useFrame((state) => {
    if (useCamera.getState().isZooming) {
      setZoom();
    }
  });

  const setZoom = () => {
    const activeLevel = useSection.getState().activeLevel;

    const sign = cameraPosition.x < 0 ? -1 : 1;

    wrapper.current.position.x = cameraPosition.x + Math.sin(activeLevel * 1.57) * 1.5 * sign;
    wrapper.current.position.z = cameraPosition.z - activeLevel * 2.5;
  };

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} position={[0, 1, 0]}>
        <PerspectiveCamera ref={camera} position={[0, 0, zoom.current]} makeDefault={true} near={0.1} far={100} />
      </group>

      {/* <PerspectiveCamera ref={camera} position={[0, 2, 8]} makeDefault={true} near={0.1} far={100} /> */}
      {/* <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}
