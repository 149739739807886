import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';

import { extend, useFrame, useThree } from '@react-three/fiber';
import { shaderMaterial, useTexture } from '@react-three/drei';
import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

// const OuterMaterial = shaderMaterial(
//   {
//     uFrequency: new THREE.Vector2(1, 1),
//     size: 0,
//     uTime: 0,
//     uSize: 0.1,
//     uWaveTime: 0,
//     uColorTime: 0,
//     uOpacity: 1,
//   },
//   vertexShader,
//   fragmentShader
// );

// extend({ OuterMaterial });

export function Orb({ active, hovered, started, material }) {
  /*
   * properties
   */

  const group = useRef();
  const mesh = useRef();
  const pct = useRef(0);

  // const normalMap = useTexture('/textures/Coins_Normal_01.png');

  //   const material = useRef();

  //   const props = {
  //     frequencyX: 4,
  //     frequencyY: 3,
  //     size: 0.12,
  //     waveSpeed: 3.5,
  //     colorSpeed: 0,
  //     uOpacity: 0.3,
  //   };

  /*
   * hooks
   */

  useFrame((state, delta) => {
    if (pct.current > 0) {
      const time = state.clock.elapsedTime;

      mesh.current.rotation.set(time * 0.32, time * 0.51, time * 0.44);

      mesh.current.scale.x = 0.98 + 0.04 * Math.sin(time * 4.9) * pct.current;
      mesh.current.scale.y = 0.98 + 0.04 * Math.cos(time * 3.2) * pct.current;
      mesh.current.scale.z = 0.95 + 0.04 * Math.sin(time * 4.1) * pct.current;

      group.current.scale.x = 0.98 + 0.04 * Math.sin(time * 2.4) * pct.current;
      group.current.scale.y = 0.98 + 0.04 * Math.cos(time * 3.2) * pct.current;
      group.current.scale.z = 0.98 + 0.04 * Math.sin(time * 2.8) * pct.current;
    }

    // material.current.uniforms.uTime.value += delta;
    // material.current.uniforms.uColorTime.value += delta * props.colorSpeed;
    // material.current.uniforms.uWaveTime.value += delta * props.waveSpeed;
    // material.current.uniforms.uOpacity.value = props.uOpacity;

    // let pct = useHold.getState().holdPct;
    // pct = Math.min(1, pct * 2);

    // material.current.uniforms.uSize.value = 0.12 + pct * 0.51;
    // material.current.uniforms.uWaveTime.value += delta * (3.5 + 10 * pct);
    // material.current.uniforms.uColorTime.value += delta * (0.05 + 0.3 * pct);
    // material.current.uniforms.uOpacity.value = (1 - pct) * 0.2;

    // let scale = 1 - pct * 0.6;
    // scale = Math.pow(scale, 0.9);
    // mesh.current.scale.set(scale, scale, scale);
  });

  useEffect(() => {
    // console.log(isZooming);
    if (hovered) {
      gsap.to(pct, { current: 1, duration: 0.4, ease: 'sine.out' });
    } else {
      gsap.to(pct, { current: 0, duration: 0.4, ease: 'sine.out' });
    }
  }, [hovered]);

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={group}>
        <mesh ref={mesh} material={material}>
          <sphereGeometry args={[0.3, 64, 64]} />

          {/* <cylinderGeometry args={[0.3, 0.3, 0.1, 32]} /> */}

          {/* <outerMaterial
          // depthTest={false}
          depthWrite={false}
          ref={material}
          side={THREE.DoubleSide}
          transparent
          uTime={0}
          uSize={props.size}
          uFrequency={[props.frequencyX, props.frequencyY]}
        /> */}

          {/* <meshStandardMaterial
            normalMap={normalMap}
            normalScale={new THREE.Vector2(10, 10)}
            color="#dedede"
            metalness={0.1}
            roughness={.8}
          /> */}
        </mesh>
      </mesh>
    </>
  );
}
