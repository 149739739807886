import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import { useSounds } from '../../../stores/useSounds';
import { useInstructions } from '../../../stores/useInstructions';
import style from './Intro.module.css';
import { StandardButton } from '../../component/buttons/StandardButton';
import gsap from 'gsap';

export function Intro() {
  /*
   * properties
   */

  const playSound = useSounds((state) => state.playSound);
  const getSound = useSounds((state) => state.getSound);
  const watched = useInstructions((state) => state.watched);
  const setWatched = useInstructions((state) => state.setWatched);

  const wrapper = useRef();
  const buttonWrapper = useRef();
  const [shown, setShown] = useState(false);
  const segment = useSection((state) => state.segment);
  const setSegment = useSection((state) => state.setSegment);

  const [showInstructions, setShowInstructions] = useState(false);

  /*
   * hooks
   */

  useEffect(() => {
    if (!shown && segment === 'intro') {
      setShown(true);

      setTimeout(() => {
        const sound = getSound('introEnter');
        sound.play();
        sound.fade(0, 0.5, 300);

        gsap.to(buttonWrapper.current, {delay: 3, opacity: 1})
      }, 1000);
    }
  }, [segment]);

  const continueHandler = () => {
    // console.log('continueHandler');
    // console.log(useInstructions.getState().watched);
    // setShowInstructions(true);
    // setSegment('intro2');

    if (useInstructions.getState().watched) {
      endHandler();
    } else {
      setTimeout(() => {
        playSound('introNavigate');
        // playSound('transition');
      }, 1000);

      setWatched();
      setShowInstructions(true);
      setSegment('intro2');
    }
  };

  const endHandler = () => {
    setTimeout(() => {
      // playSound('introNavigate');
      playSound('transition');
    }, 1000);

    setSegment('tree');

    gsap.to(wrapper.current, { duration: 1, opacity: 0, ease: 'power3.inOut', onComplete: hide });
  };

  const hide = () => {
    setShown(false);
  };

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <div ref={wrapper} className={style.intro}>
          <Window shown={!showInstructions}>
            <div className={style.title}>
              <div className={style.lineLeft} />
              <h1 className="text-eyebrow">OMEN</h1>
              <div className={style.lineRight} />
            </div>
            <div ref={buttonWrapper} className={style.buttonWrapper}>
              <StandardButton onClick={continueHandler}>
                {watched ? "Let's begin..." : 'How to navigate...'}
              </StandardButton>
            </div>
          </Window>

          <Window shown={showInstructions}>
            <div className={style.title}>
              <div className={style.lineLeft} />
              <h1 className="text-eyebrow">CONTROLS</h1>
              <div className={style.lineRight} />
            </div>
            <div className={style.videoWrapper}>
              <video autoPlay muted loop playsInline className={style.video}>
                <source src="/videos/instructions.mp4" type="video/mp4" />
              </video>
            </div>
            <StandardButton onClick={endHandler}>Let's begin...</StandardButton>
          </Window>
        </div>
      )}
    </>
  );
}

function Window({ children, shown }) {
  const wrapper = useRef();

  useEffect(() => {
    if (shown) {
      gsap.to(wrapper.current, { delay: 0.5, opacity: 1, duration: 0.5, ease: 'power1.in' });
    } else {
      gsap.to(wrapper.current, { opacity: 0, duration: 0.5, ease: 'power1.out' });
    }
  }, [shown]);

  return (
    <>
      <div ref={wrapper} className={style.window} style={{ pointerEvents: shown ? 'all' : 'none' }}>
        {children}
      </div>
    </>
  );
}
