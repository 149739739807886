import { create } from 'zustand';
import * as THREE from 'three';

export const useMaterials = create((set) => ({
  genericMaterial: new THREE.MeshStandardMaterial({
    // side: THREE.BackSide,
    color: '#dedede',
    metalness: 0.78,
    roughness: 0.65,
  }),

  // genericMaterial: new THREE.MeshStandardMaterial({
  //   color: '#ffffff',
  //   metalness: 0.5,
  //   roughness: .9,
  //   emissive: '#444444',
  //   emissiveIntensity: 1,
  // }),

  redMaterial: new THREE.MeshBasicMaterial({
    // color: 'red',
    color: '#f3001d',
    blending: THREE.AdditiveBlending,
  }),
}));
