import { useFrame } from '@react-three/fiber'
import { BigText } from '../text/BigText'
import { useSection } from '../../../stores/useSection'
import { useEffect, useRef, useState } from 'react'
import style from './SectionTitle.module.css'
import { Html } from '@react-three/drei'
import gsap from 'gsap'
import { Icon } from '../../../app2D/component/Icon'
import { useSanityContent } from '../../../stores/useSanityContent'

export function SectionTitle() {
  /*
   * properties
   */

  const hoveredSection = useSection((state) => state.hoveredSection)

  const c = useSanityContent((state) => state.content)
  const hoveredContent = c?.points?.find(
    (item) => item.model === hoveredSection,
  )

  const panel = useRef()
  const [showText, setShowText] = useState(false)
  // const [title, setTitle] = useState(false);
  const [content, setContent] = useState()
  const [showButton, setShowButton] = useState(false)

  /*
   * hooks
   */

  // useEffect(() => {
  //   console.log('SectionTitle :: onMount');
  // }, []);

  useEffect(() => {
    // console.log('hoveredSection: ' + hoveredSection);

    if (panel.current) {
      if (hoveredSection) {
        setShowText(true)
        // setTitle(useContent.getState().getCaption(hoveredSection));
        setContent(hoveredContent)

        // if (!useContent.getState().isActive(hoveredSection)) {
        gsap.to(panel.current, {
          opacity: 1,
          delay: 0.25,
          duration: 1.5,
          ease: 'power1.out',
        })
        // }
      } else {
        setShowText(false)
        gsap.killTweensOf(panel.current)
        gsap.to(panel.current, {
          opacity: 0,
          duration: 0.4,
          ease: 'power1.out',
        })
      }
    }
  }, [hoveredSection])

  useEffect(() => {}, [])

  const hideHandler = () => {
    setShowText(false)
    setShowButton(false)
    setContent(false)
  }

  // useEffect(() => {
  //   console.log(content);
  // }, [content]);

  /*
   * visuals
   */

  return (
    <>
      <group position={[0, 2.2, 2.6]}>
        <Html
          wrapperClass={style.wrapper}
          zIndexRange={[100, 0]}
          transform
          distanceFactor={1}
          position={[0, -0.2, 0]}
        >
          <div ref={panel} className={style.panel}>
            {content && content.subhead && (
              <div className={style.header}>
                <div className={style.lineLeft} />
                <p className={style.title}>{content.subhead}</p>
                <div className={style.lineRight} />
              </div>
            )}
            {content && !content.subhead && <div />}
            {content && content.blurb && (
              <div className={style.footer}>
                <p className={style.body}>{content.blurb}</p>
                {content.highlight && (
                  <p className={style.info}>{content.highlight}</p>
                )}
              </div>
            )}
            {content && content.link && (
              <div className={`${style.iconContainer} ${style[content.id]}`}>
                <Icon svg="arrow" />
              </div>
            )}
            {showButton && (
              <div className={style.buttonWrapper}>
                <StandardButton
                  responsive={false}
                  onClick={() => {
                    if (hoveredSection) {
                      const caption = useContent
                        .getState()
                        .getCaption(hoveredSection)
                      if (caption && caption.link) {
                        window.open(caption.link)
                      } else {
                        clickSection(hoveredSection)
                      }
                    }
                  }}
                >
                  Take me there
                </StandardButton>
              </div>
            )}
          </div>
        </Html>
        <BigText
          text={content && content.title}
          position={[0, 0, 0]}
          show={showText}
          showEase="power1.out"
          delay={0.2}
          fontSize={0.7}
        />
      </group>
    </>
  )
}
