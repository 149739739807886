import { useFrame } from '@react-three/fiber';
import style from './Line.module.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';

export function Line({ index, single }) {
  /*
   * properties
   */

  const line = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    loop();
  }, []);

  const loop = () => {
    if (line.current && index > 0) {
      let alpha = useSection.getState().activeLevel - (index - 1);
      alpha = Math.max(0, Math.min(1, alpha));
      line.current.style.opacity = alpha;
      line.current.style.transform = `scaleX(${alpha})`;

      requestAnimationFrame(loop);
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={line} className={`${style.line} ${index === 0 ? style.big : ''} ${single ? style.single : ''}`}></div>
    </>
  );
}
