import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Skull } from './models/Skull';
import { Bee } from './models/Bee';
import { Coins } from './models/Coins';
import { Ram } from './models/Ram';
import { Cross } from './models/Cross';
import { Sphere } from './models/Sphere';
import { Ghost } from './models/Ghost';
import { AnimatedGhost } from './models/AnimatedGhost';
import { useMaterials } from '../../../stores/useMaterials';
import { useMainScroll } from '../../../stores/useMainScroll';
import * as THREE from 'three';
import { Orb } from './models/Orb';

export function ObjectInner({ index, section, active, hovered, started }) {
  /*
   * properties
   */

  const genericMaterial = useMaterials((state) => state.genericMaterial);
  // const redMaterial = useMaterials((state) => state.redMaterial);
  const redMaterial = new THREE.MeshBasicMaterial({
    color: '#f3001d',
    blending: THREE.AdditiveBlending,
    transparent: true,
  });

  const red = useRef();
  const white = useRef();

  const overPct = useRef(0);

  /*
   * hooks
   */

  useEffect(() => {
    gsap.killTweensOf(overPct);

    // console.log('hovered: ' + hovered)
    // console.log('active: ' + active)

    if (active || hovered) {
      gsap.to(overPct, { current: 1, duration: 0.45, ease: 'power2.out' });
    } else {
      gsap.to(overPct, { current: 0, duration: 0.12, ease: 'power4.in' });
    }
  }, [active, hovered]);

  useFrame((state) => {
    // const scrollPct = Math.min(1, useMainScroll.getState().scrollSpeed);
    // const pct = Math.max(overPct.current, scrollPct);

    const pct = overPct.current;

    const sign = index < 3 ? 1 : -1;

    if (pct > 0.01) {
      red.current.visible = true;

      redMaterial.opacity = pct;

      const scale = 0.92 + pct * 0.08;
      red.current.position.x = 1 * pct * 0.035 * sign;
      red.current.scale.set(scale, scale, scale);
    } else {
      red.current.visible = false;
    }

    const time = state.clock.elapsedTime;
    white.current.position.y = Math.sin(time * 2 + index * 0.75) * 0.02;
    red.current.position.y = Math.sin(time * 2 + index * 0.75) * 0.02;
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={white}>
        <Inner section={section} active={active} hovered={hovered} started={started} material={genericMaterial} />
      </group>
      <group ref={red} visible scale={0} position={[index < 3 ? 0.03 : -0.03, 0.03, -0.025]}>
        <Inner
          section={section}
          active={active}
          hovered={hovered}
          started={started}
          material={redMaterial}
          red={true}
        />
      </group>
    </>
  );
}

function Inner({ section, active, hovered, material, started, red }) {
  return (
    <>
      {section === 'Coins' && (
        <Coins active={active} hovered={hovered} started={started} material={material} red={red} />
      )}
      {section === 'Bee' && <Bee active={active} hovered={hovered} started={started} material={material} />}
      {section === 'Ram' && <Ram active={active} hovered={hovered} started={started} material={material} />}
      {section === 'Cross' && <Cross active={active} hovered={hovered} started={started} material={material} />}
      {section === 'Skull' && <Skull active={active} hovered={hovered} started={started} material={material} />}
      {/* {section === 'Sphere' && <Sphere active={active} hovered={hovered} started={started} material={material} />} */}
      {/* {section === 'Ghost' && <Ghost active={active} hovered={hovered} started={started} material={material} />} */}
      {/* {section === 'Ghost' && <AnimatedGhost active={active} hovered={hovered} started={started} material={material} red={red} />} */}
      {section === 'Ghost' && <Orb active={active} hovered={hovered} started={started} material={material} />}
    </>
  );
}
