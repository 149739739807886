import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Html } from '@react-three/drei';
import style from './Panel.module.css';
import { useSection } from '../../../../stores/useSection';
import { StandardButton } from '../../../../app2D/component/buttons/StandardButton';
import { useFrame } from '@react-three/fiber';
import { Panel } from './Panel';

export function Panels({ index, section, content, allPanelContent }) {
  /*
   * properties
   */


  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      {content && content.map((value, loopIndex) => (
        <Panel
          key={loopIndex}
          index={index}
          allPanelContent={allPanelContent}
          section={section}
          content={value}
          level={loopIndex}
          total={content.length}
        />
      ))}
    </>
  );
}
