import { MeshPortalMaterial, PerspectiveCamera } from '@react-three/drei';
import { RootModel } from './RootModel';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { RootCopy } from './RootCopy';
import { useScene } from '../../../stores/useScene';
import gsap from 'gsap/gsap-core';

export function Root() {
  /*
   * properties
   */

  const camera = useRef();
  const modelWrapper = useRef();
  const plane = useRef();
  const mesh = useRef();
  const wrapper = useRef();

  const isShowingRoot = useScene((state) => state.isShowingRoot);

  /*
   * hooks
   */

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  }, []);

  const resizeHandler = () => {
    const windowRatio = window.innerWidth / window.innerHeight;

    if (windowRatio > 1) {
      mesh.current.scale.set(windowRatio, 1, 1);
    } else {
      mesh.current.scale.set(1, 1 / windowRatio, 1);
    }
  };

  useFrame((state) => {
    modelWrapper.current.rotation.y = state.clock.elapsedTime * 0.2;
  });

  useEffect(() => {
    let posY = isShowingRoot ? 0 : -6;
    let posZ = isShowingRoot ? 0 : -2;

    if (wrapper.current) {
      gsap.to(wrapper.current.position, { y: posY, z: posZ, duration: 1.5, ease: 'power4.inOut' });
    }
  }, [isShowingRoot]);

  return (
    <>
      <mesh ref={mesh} position={[0, 1, 6]}>
        {/* <planeGeometry args={[1.865, 1.865]} /> */}
        <planeGeometry args={[3, 1.865]} />
        <MeshPortalMaterial worldUnits={false}>
          <directionalLight color="#ffffff" intensity={.5} position={[-5.5, -3, -10]} />
          <directionalLight color="#ffffff" intensity={1} position={[5, -3, -10]} />

          <PerspectiveCamera
            ref={camera}
            rotation={[0, 0, 0]}
            position={[0, 0, 10]}
            makeDefault={true}
            near={0.1}
            far={100}
          />

          <color attach="background" args={['#161616']} />
          {/* <color attach="background" args={['#00ff00']} /> */}
          <mesh ref={wrapper}>
            <RootCopy />
            <group ref={modelWrapper}>
              <RootModel scale={36} position={[0, 3.5, 0]} />
            </group>
          </mesh>
        </MeshPortalMaterial>
      </mesh>
    </>
  );
}
