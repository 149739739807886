import { create } from 'zustand';

export const useContent = create((set, get) => ({
  content: {
    Coins: {
      active: true,
      // caption: 'Tokenomics',
      title: 'Tokenomics',
      caption: {
        id: 'tokenomics',
        title: 'Tokenomics',
        subtitle: '$OMNI',
        // type: 'standard',
        body: 'Deep dive into our ecosystem token $OMNI',
      },
      content: [
        {
          type: 'oneliner',
          title: 'Legal: 5%',
        },
        {
          type: 'oneliner',
          title: 'Strategic / Partners: 5%',
        },
        {
          type: 'oneliner',
          title: 'Team: 15%',
        },
        {
          type: 'oneliner',
          title: 'Marketing: 10%',
        },
        {
          type: 'oneliner',
          title: 'DAO Treasury: 40%',
        },
        {
          type: 'oneliner',
          title: 'Airdrop: 5%',
        },
        {
          type: 'oneliner',
          title: '$SKULL to $OMNI: 13%',
        },
        {
          type: 'oneliner',
          title: 'LP: 7%',
        },
        {
          type: 'grid',
        },
        // {
        //   title: 'Tokenomics L1 Lorem Ipsum Dolor Amet',
        //   body: 'Tokenomics L1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
      ],
    },
    Bee: {
      active: false,
      // caption: 'DAO',
      title: 'DAO',
      caption: {
        id: 'dao',
        title: 'DAO',
        subtitle: 'Coming soon',
        // type: 'info',
        body: 'A Community Made System Developed to distribute decision-making, management, and entity ownership of our Treasury.',
        highlight: 'Current Treasury:  $0,000,000',
      },
      content: [
        // {
        //   type: 'link',
        //   title: 'X / Twitter',
        //   link: 'https://twitter.com/',
        // },
        // {
        //   type: 'link',
        //   title: 'Discord',
        //   link: 'https://discord.com/',
        // },
      ],
    },
    Ram: {
      active: false,
      // caption: 'PHTR',
      title: 'PHTR',
      caption: {
        id: 'phtr',
        title: 'PHTR',
        subtitle: 'Legacy',
        link: 'https://omen.ada-anvil.io/en/',
        // type: 'info',
        body: 'Take me to legacy PHTR staking platform...',
        highlight: 'V2 Coming soon',
      },
      content: [
        // {
        //   title: 'Ram L1 Lorem Ipsum Dolor Amet',
        //   body: 'Ram L1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
        // {
        //   title: 'Ram L2 Lorem Ipsum Dolor Amet',
        //   body: 'Ram L2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
        // {
        //   title: 'Ram L3 Lorem Ipsum Dolor Amet',
        //   body: 'Ram L3 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
      ],
    },
    Cross: {
      active: true,
      // caption: 'Community',
      title: 'Community',
      caption: {
        id: 'community',
        title: 'Community',
        // type: 'standard',
      },
      content: [
        {
          type: 'link',
          title: 'X / Twitter',
          link: 'https://twitter.com/Omen4Omen',
        },
        {
          type: 'link',
          title: 'Discord',
          link: 'https://discord.gg/qfjygwT6z2',
        },
        {
          type: 'link',
          title: 'Medium',
          link: 'https://medium.com/@omen.nemonium',
        },
        {
          type: 'link',
          title: 'Farming',
          link: 'https://omen.farmroll.io/',
        },
      ],
    },
    Skull: {
      active: false,
      // caption: 'Omen STKEpool',
      title: 'Omen Stakepool',
      caption: {
        id: 'stkepool',
        title: 'OMEN Stakepool',
        // type: 'external',
        body: 'OMEN stakepool is the central part of Nemonium Ecosystem. While supporting the network the Stakepool rewards are 100% re distributed back to OMEN holders.',
        link: 'https://pool.pm/bbd852e7fb67e8f182def1f99d22a51c76b40222ab47d0682223e606',
      },
      content: [
        // {
        //   title: 'Skull L1 Lorem Ipsum Dolor Amet',
        //   body: 'Skull L1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
        // {
        //   title: 'Skull L2 Lorem Ipsum Dolor Amet',
        //   body: 'Skull L2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas facilisis ullamcorper dolor, sed feugiat est pulvinar sed.',
        // },
      ],
    },
    // Sphere: {
    Ghost: {
      active: true,
      // caption: 'Marketplace',
      title: 'Marketplaces',
      caption: {
        id: 'marketplaces',
        title: 'Marketplaces',
        // subtitle: 'Omen',
        // type: 'external',
        body: 'Find an Omen that fits you best',
        // link: 'https://www.wayup.io/collection/da286f15e0de865e3d50fec6fa0484d7e2309671dc4ba8ce6bdd122b',
      },
      content: [
        {
          type: 'link',
          title: 'Wayup',
          link: 'https://www.wayup.io/collection/da286f15e0de865e3d50fec6fa0484d7e2309671dc4ba8ce6bdd122b',
        },
        {
          type: 'link',
          title: 'JPG Store',
          link: 'https://www.jpg.store/collection/omen?tab=items',
        },
        {
          type: 'link',
          title: 'Jamonbread',
          link: 'https://jamonbread.io/collections/omen',
        },
      ],
    },
  },

  getCaption: (section) => {
    return get().content[section].caption;
  },

  getTitle: (section) => {
    return get().content[section].title;
  },

  getContent: (section) => {
    return get().content[section].content;
  },

  geAllContent: () => {
    return get().content;
  },

  isActive: (section) => {
    return get().content[section].active;
  },
}));
