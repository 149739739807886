import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useInstructions = create(
  persist(
    (set) => ({
      watched: false,

      setWatched: () =>
        set({
          watched: true,
        }),
    }),
    {
      name: 'watched_instructions',
    }
  )
);
