import { create } from 'zustand';

export const useMainScroll = create((set, get) => ({
  pct: 0,
  scrollSpeed: 0,
  isHoveringHole: false,
  // enabled: true,

  // enableScroll: () => set({ pct: 0, enabled: true }),
  // disableScroll: () => set({ pct: 0, enabled: false }),

  setPct: (pct) => set({ pct }),
  setScrollSpeed: (scrollSpeed) => set({ scrollSpeed }),
  setIsHoveringHole: (isHoveringHole) => set({ isHoveringHole }),
}));
