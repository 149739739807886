import { useEffect, useRef } from 'react'
import { useSection } from '../../../stores/useSection'
import gsap from 'gsap'
import { useMainScroll } from '../../../stores/useMainScroll'
import { Dust } from '../dust/Dust'
import { Intro } from '../intro/Intro'
import { SectionTitle } from '../sectionTitle/SectionTitle'
import { Hole } from '../hole/Hole'
import { Fairy } from '../fairy/Fairy'
import { Landscape } from '../landscape/Landscape'
import { Loop } from '../loop/Loop'
import { Objects } from '../objects/Objects'
import { useFrame } from '@react-three/fiber'
import { MouseWrapper } from './MouseWrapper'
import { useScene } from '../../../stores/useScene'
import { useSanityContent } from '../../../stores/useSanityContent'

export function MainScene() {
  /*
   * properties
   */

  const wrapper = useRef()
  const scrollStartWrapper = useRef()
  const scrollWrapper = useRef()
  const segment = useSection((state) => state.segment)
  const shown = useRef(false)
  const zoom = useRef(0)

  // const isShowingRoot = useScene((state) => state.isShowingRoot);

  /*
   * hooks
   */

  useEffect(() => {
    if (segment === 'intro') {
      gsap.to(wrapper.current.rotation, {
        x: 0,
        y: 0,
        delay: 0,
        duration: 3,
        ease: 'power4.inOut',
      })
    } else if (!shown.current && segment === 'tree') {
      shown.current = true
      // const tl = gsap.timeline();
      // tl.to(wrapper.current.rotation, { x: 0, y: -0.2, delay: 0, duration: 1.2, ease: 'sine.inOut' });
      // tl.to(wrapper.current.rotation, { x: 0, y: 0.2, delay: 0, duration: 1.8, ease: 'sine.inOut' });
      // tl.to(wrapper.current.rotation, { x: 0, y: 0, delay: 0, duration: 1.2, ease: 'sine.inOut' });

      gsap.to(scrollStartWrapper.current.rotation, {
        y: 0,
        delay: 0,
        duration: 3,
        ease: 'power4.inOut',
      })
    }
  }, [segment])

  useFrame(() => {
    const scrollPct = useMainScroll.getState().pct
    scrollWrapper.current.rotation.y = scrollPct * 6.2832

    let dist = 1 - Math.abs(scrollPct - 0.5) * 7
    dist = Math.max(0, Math.min(1, dist * 1.5))

    let scale = 1
    if (dist > 0) {
      scale += zoom.current * 0.5 * dist
    }

    wrapper.current.scale.set(scale, scale, scale)
  })

  const onHoleOver = () => {
    gsap.to(zoom, { current: 1, duration: 0.6, ease: 'power1.inOut' })
  }

  const onHoleOut = () => {
    gsap.to(zoom, { current: 0, duration: 0.6, ease: 'power1.inOut' })
  }

  /*
   * visuals
   */

  const content = useSanityContent((state) => state.content)

  return (
    <>
      <MouseWrapper>
        <group ref={wrapper} rotation={[-0.1, -0.5, 0]}>
          <Dust />
          <Intro />
          <SectionTitle />

          <group ref={scrollStartWrapper} rotation={[0, -Math.PI, 0]}>
            <group ref={scrollWrapper}>
              <Hole onOver={onHoleOver} onOut={onHoleOut} />
              <Fairy />
              <Landscape scale={(33, 33, 33)} />
            </group>
          </group>

          <Loop />
          <Objects content={content} />
        </group>
      </MouseWrapper>
    </>
  )
}
