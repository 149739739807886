import style from './EasterEgg.module.css';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { useMainScroll } from '../../../stores/useMainScroll';
import { StandardButton } from '../buttons/StandardButton';
import { Icon } from '../Icon';

export function EasterEgg() {
  /*
   * properties
   */

  const container = useRef();
  const isHoveringHole = useMainScroll((state) => state.isHoveringHole);
  const [shown, setShown] = useState(true);

  /*
   * hooks
   */

  useEffect(() => {
    gsap.killTweensOf(container.current);
    if (isHoveringHole) {
      setShown(true);

      gsap.to(container.current, { opacity: 1, duration: 1, ease: 'power4.inOut' });
    } else {
      if (container.current) {
        gsap.to(container.current, {
          opacity: 0,
          duration: 1,
          ease: 'power4.out',
          onComplete: () => {
            setShown(false);
          },
        });
      }
    }
  }, [isHoveringHole]);

  const clickHandler = () => {
    window.open('https://omenswap.ada-anvil.io/en/', '_blank');
  };

  /*
   * visuals
   */

  return (
    <>
      <div ref={container} className={style.eggContainer}>
        {shown && (
          <>
            {/* <p className={style.content}>Deep dive into our ecosystem token $OMNI.</p>
            <p className={style.content}>Deep dive into our ecosystem token $OMNI.</p>
            <div className={style.background} /> */}
            <StandardButton onClick={clickHandler}>
              Portal
              <span className={style.iconContainer}>
                <Icon svg="arrow" />
              </span>
            </StandardButton>
          </>
        )}
      </div>
    </>
  );
}
