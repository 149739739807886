import { useEffect, useMemo, useRef, useState } from 'react';
import gsap from 'gsap';
import { Html } from '@react-three/drei';
import style from './Panel.module.css';
import { useSection } from '../../../../stores/useSection';
import { useCamera } from '../../../../stores/useCamera';
import { StandardButton } from '../../../../app2D/component/buttons/StandardButton';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

export function PanelDot({ level, sign, redMaterial }) {
  /*
   * properties
   */

  const sphere = useRef();
  const shown = useRef(false);

  /*
   * hooks
   */

  useFrame((state) => {
    const activeLevel = useSection.getState().activeLevel;
    const isZooming = useCamera.getState().isZooming;

    if (activeLevel >= level && !shown.current && isZooming) {
      shown.current = true;
      show();
    } else if ((activeLevel < level || !isZooming) && shown.current) {
      shown.current = false;
      hide();
    }
  });

  const show = () => {
    if (sphere.current) {
      gsap.to(sphere.current.scale, {
        x: 1,
        y: 1,
        z: 1,
        duration: 0.5,
        ease: 'power4.out',
      });
    }
  };

  const hide = () => {
    if (sphere.current) {
      gsap.to(sphere.current.scale, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.4,
        ease: 'power2.in',
      });
    }
  };

  /*
   * visuals
   */

  return (
    <>
      <mesh ref={sphere} position={[1 * sign, -0.7, 0]} scale={0} material={redMaterial}>
        <sphereGeometry args={[0.03, 16, 8]} />
      </mesh>
    </>
  );
}
