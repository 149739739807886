import { create } from 'zustand';

export const useCamera = create((set) => ({
  cameraPosition: null,
  isZooming: false,
  isTransitioning: false,

  resetCamera: () => set({ cameraPosition: null }),
  setCamera: (cameraPosition, zoom) => set({ cameraPosition }),

  startZooming: () => set({ isZooming: true }),
  stopZooming: () => set({ isZooming: false }),

  startTransitioning: () => set({ isTransitioning: true }),
  stopTransitioning: () => set({ isTransitioning: false }),
}));
