import { create } from 'zustand';

export const useLight = create((set) => ({
  lightPosition: null,
  isOn: true,

  resetLightPosition: () => set({ lightPosition: null }),
  setLightPosition: (lightPosition) => set({ lightPosition }),

  switchOn: () => set({ isOn: true }),
  switchOff: () => set({ isOn: false }),
}));
