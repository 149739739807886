import { useEffect, useRef, useState } from 'react';
import { useSection } from '../../../stores/useSection';
import style from './Loader.module.css';
import gsap from 'gsap';
import { StandardButton } from '../../component/buttons/StandardButton';
import { useProgress } from '@react-three/drei';
import { loadSounds, useSounds } from '../../../stores/useSounds';
import { Animation } from './Animation';
import { useLoader } from '../../../stores/useLoader';

export function Loader() {
  /*
   * properties
   */

  const setSoundOn = useSounds((state) => state.setSoundOn);
  // const setReady = useLoader((state) => state.setReady);

  const wrapper = useRef();
  const buttonCenter = useRef();
  const buttonBottom = useRef();

  const [enabled, setEnabled] = useState(false);
  const [shown, setShown] = useState(false);
  const [enter, setEnter] = useState(false);
  const segment = useSection((state) => state.segment);
  const setSegment = useSection((state) => state.setSegment);

  const { progress, total } = useProgress();

  /*
   * hooks
   */

  useEffect(() => {
    // console.log(total, progress);
    if (progress === 100 && total > 0) {
      // console.log('go go go');
      loadSounds();
      setEnabled(true);
      // gsap.to(buttonCenter.current, { delay: 0.4, duration: 0.6, opacity: 1, ease: 'power2.in' });
      // gsap.to(buttonBottom.current, { delay: 0.8, duration: 0.6, opacity: 1, ease: 'power2.in' });
    }
  }, [progress, total]);

  useEffect(() => {
    if (!shown && segment === 'loader') {
      setShown(true);
    }
  }, [segment]);

  const continueWithoutSound = () => {
    setSoundOn(false);
    continueHandler();
  };

  const continueHandler = () => {
    if (!enter) {
      setEnter(true);
      setSegment('intro');
      gsap.to(buttonCenter.current, { delay: 0, duration: 0.6, opacity: 0, ease: 'power2.in' });
      gsap.to(buttonBottom.current, { delay: 0.4, duration: 0.6, opacity: 0, ease: 'power2.in' });

      setTimeout(hide, 4000);
    }
  };

  const hide = () => {
    setShown(false);
  };

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <>
          <div ref={wrapper} className={style.loader}>
            <Animation progress={progress} enter={enter} />
            <div ref={buttonCenter} className={style.center}>
              <StandardButton disabled={!enabled} onClick={continueHandler}>
                <span className={style.buttonText}>{enabled ? 'Enter' : 'loading'}</span>
              </StandardButton>
            </div>
            <div ref={buttonBottom} className={style.footer}>
              <button disabled={!enabled} className={style.button} onClick={continueWithoutSound}>
                Enter without audio
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
