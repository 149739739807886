/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useMaterials } from '../../../../../stores/useMaterials';
// import { AnimatedObject } from './AnimatedObject';

// useGLTF.preload("/models/Floating_Objects/01_Omen_CROSS_01.glb");

export function Cross(props) {
  const { nodes, animations } = useGLTF('/models/Floating_Objects/01_Omen_CROSS_01.glb');
  const genericMaterial = useMaterials((state) => state.genericMaterial);
  return (
    <group scale={0.2}>
      <group name="Scene" scale={0.85} position={[0, 0.2, 0]}>
        <group name="Cross" rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            name="Cross_2"
            castShadow
            receiveShadow
            geometry={nodes.Cross_2.geometry}
            // material={props.material}
            // material={genericMaterial}
          >
            <meshStandardMaterial color="#222222" metalness={0.9} fog={true} roughness={0.4} />
          </mesh>
        </group>
      </group>
    </group>
  );
}
