import { useEffect, useRef } from 'react';
import { useScene } from '../stores/useScene';
import { useSection } from '../stores/useSection';
import { useMainScroll } from '../stores/useMainScroll';
import { useSounds } from '../stores/useSounds';

export function MusicController() {
  /*
   * properties
   */

  const segment = useSection((state) => state.segment);
  const isShowingRoot = useScene((state) => state.isShowingRoot);
  const isHoveringHole = useMainScroll((state) => state.isHoveringHole);
  // const pct = useMainScroll((state) => state.pct);

  const loop = useRef();

  const inited = useRef(false);
  const activeLoop = useRef();
  const loops = useRef();

  /*
   * hooks
   */

  useEffect(() => {
    // console.log('MusicController :: onMount');
    if (segment === 'intro') {
      setTimeout(() => {
        // console.log('MusicController :: init');
        inited.current = true;

        loops.current = {
          mainLoop: useSounds.getState().getSound('mainLoop'),
          treeLoop: useSounds.getState().getSound('treeLoop'),
          rootsLoop: useSounds.getState().getSound('rootsLoop'),
        };

        setMusic();
      }, 1000);
    }
  }, [segment]);

  useEffect(() => {
    setMusic();
  }, [isShowingRoot, isHoveringHole]);

  const setMusic = () => {
    // console.log('setMusic');
    if (inited.current) {
      let music;

      if (isShowingRoot) {
        music = 'rootsLoop';
      } else if (isHoveringHole) {
        music = 'treeLoop';
      } else {
        music = 'mainLoop';
      }

      if (loop.current != music) {
        if (activeLoop.current) {
          // console.log('fade previous loop away, volume:');
          // console.log(activeLoop.current.volume());
          activeLoop.current.fade(activeLoop.current.volume(), 0, 600);
        }

        loop.current = music;

        activeLoop.current = loops.current[loop.current];
        // console.log(activeLoop.current)
        activeLoop.current.volume(0)

        // console.log('fade new loop up, volume:');
        // console.log(activeLoop.current.volume());

        activeLoop.current.stop();
        activeLoop.current.play();
        activeLoop.current.fade(0, 0.2, 600);
      }
    }
  };
}
