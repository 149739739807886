import { Fragment, useEffect, useRef, useState } from 'react'
import { useScene } from '../../../stores/useScene'
import { Icon } from '../Icon'
import style from './RootContent.module.css'
import gsap from 'gsap'
import { useSanityContent } from '../../../stores/useSanityContent'

export function RootContent() {
  /*
   * properties
   */

  const container = useRef()
  const isShowingRoot = useScene((state) => state.isShowingRoot)
  const hideRoot = useScene((state) => state.hideRoot)
  const [shown, setShown] = useState(false)
  const content = useSanityContent((state) => state.content)

  /*
   * hooks
   */

  useEffect(() => {
    let timeoutId
    if (isShowingRoot) {
      timeoutId = setTimeout(() => {
        setShown(true)
      }, 1000)
    } else {
      if (container.current) {
        gsap.to(container.current, {
          opacity: 0,
          duration: 1,
          ease: 'power4.out',
          onComplete: () => {
            setShown(false)
          },
        })
      }
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isShowingRoot])

  useEffect(() => {
    if (shown) {
      gsap.to(container.current, {
        opacity: 1,
        duration: 1,
        ease: 'power4.out',
      })
    }
  }, [shown])

  const showSection = (section) => {
    hideRoot(section)
    // console.log(section);
  }

  /*
   * visuals
   */

  return (
    <>
      {shown && (
        <div ref={container} className={style.container}>
          <div className={style.links}>
            {content?.footer?.links?.length > 0 &&
              content?.footer?.links?.map((item, index) => {
                const isInternalLink = item?.slug?.current

                if (isInternalLink) {
                  const model = content?.points?.find(
                    (point) => point?._type === item?.docType,
                  )?.model

                  return (
                    <div key={index}>
                      <button
                        role="link"
                        aria-label={'Open ' + item?.label}
                        className={style.button}
                        onClick={() => {
                          showSection(model)
                        }}
                      >
                        {item?.label}
                      </button>
                    </div>
                  )
                } else {
                  return (
                    <a
                      className={style.link}
                      href={item?.url}
                      target="_blank"
                      key={index}
                    >
                      {item?.label}
                      <Icon svg="arrow" />
                    </a>
                  )
                }
              })}
            {/* <div>
              <button
                className={style.button}
                onClick={() => {
                  showSection('Coins');
                }}
              >
                Tokenomics
              </button>
            </div>
            <p className={style.light}>Community</p>
            <p className={style.light}>DAO</p>
            <p className={style.light}>OMEN STKEPOOL</p>
            <p className={style.light}>Ghostchain</p>
            <div>
              <a href="https://omen.ada-anvil.io/en/" target="_blank" className={style.link}>
                PHTR <Icon svg="arrow" />
              </a>
            </div> */}
          </div>
          <div className={style.info}>
            <p className={style.light}>©2024 OMEN</p>
            <a
              href="https://www.itsoffbrand.com/"
              target="_blank"
              className={style.link}
            >
              Website Designed and Built by OFF+BRAND. <Icon svg="arrow" />
            </a>
          </div>
          <div className={style.background} />
        </div>
      )}
    </>
  )
}

function Item({ name, content }) {
  const hideRoot = useScene((state) => state.hideRoot)

  return (
    <>
      {content.active === true && (
        <button
          className={style.button}
          onClick={() => {
            hideRoot(name)
          }}
        >
          {content.caption.title}
        </button>
      )}
      {content.active === false && content.caption.link && (
        <a href={content.caption.link} target="_blank" className={style.link}>
          {content.caption.title} <Icon svg="arrow" />
        </a>
      )}
      {content.active === false && !content.caption.link && (
        <p className={style.light}>{content.caption.title}</p>
      )}
    </>
  )
}
