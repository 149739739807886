import { Text, shaderMaterial } from '@react-three/drei';
import vertexShader from './shaders/vertex.glsl?raw';
import fragmentShader from './shaders/fragment.glsl?raw';
import { extend, useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const TextMaterial = shaderMaterial(
  {
    uTime: 0,
    uAlpha: 0,
  },
  vertexShader,
  fragmentShader
);

extend({ TextMaterial });

export function BigText({ text, position, show, delay, fontSize = 0.8, showEase = 'power4.in' }) {
  /*
   * properties
   */

  const material = useRef();
  const [copy, setCopy] = useState('');

  /*
   * hooks
   */
  useFrame((state, delta) => {
    material.current.uniforms.uTime.value += delta;
  });

  useEffect(() => {
    // console.log(show)
    gsap.killTweensOf(material.current.uniforms.uAlpha);
    if (show) {
      gsap.to(material.current.uniforms.uAlpha, {
        value: 0,
        delay: delay - 0.2,
        duration: 0.2,
        ease: 'linear.none',
        onComplete: () => {
          setCopy(text);
        },
      });
      gsap.to(material.current.uniforms.uAlpha, { value: 1, delay: delay, duration: 2, ease: showEase });
    } else {
      gsap.to(material.current.uniforms.uAlpha, { value: 0, duration: 1.5, ease: 'sine.out' });
    }
  }, [text, show]);

  /*
   * visuals
   */

  return (
    <>
      <Text
        position={position}
        maxWidth={4}
        textAlign="center"
        fontSize={fontSize}
        lineHeight={0.9}
        font="/fonts3D/RecklessNeue-Light.woff"
      >
        {copy}
        <textMaterial ref={material} uAlpha={0} />
      </Text>
    </>
  );
}
