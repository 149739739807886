import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';

export function Extra({ sign, hide, children }) {
  /*
   * properties
   */

  const wrapper = useRef();

  const position = useMemo(() => {
    return new THREE.Vector3(1 * sign - 1.5 + Math.random() * 3, -1.5 + Math.random() * 3, -2);
  }, []);

  const rotation = useMemo(() => {
    return new THREE.Euler(Math.random() * 3.2, Math.random() * 3.2, 0);
  }, []);

  /*
   * hooks
   */

  useEffect(() => {
    gsap.to(wrapper.current.scale, {
      delay: 0.8 + Math.random() * 0.6,
      duration: 1,
      x: 1,
      y: 1,
      z: 1,
      ease: 'power2.out',
    });
  }, []);

  useEffect(() => {
    if (hide) {
      gsap.killTweensOf(wrapper.current);
      gsap.to(wrapper.current.scale, {
        duration: 0.4,
        x: 0,
        y: 0,
        z: 0,
        ease: 'power2.in',
      });
    }
  }, [hide]);

  useFrame((state, delta) => {
    wrapper.current.rotation.x += delta * 0.221;
    wrapper.current.rotation.z += delta * 0.134;
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper} scale={0} position={position} rotation={rotation}>
        {children}
      </group>
    </>
  );
}
